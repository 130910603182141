import React, { useState, useRef, useCallback, ChangeEvent } from 'react';

import Container from 'components/container';
import Input from 'components/input';
import Textarea from 'components/textarea';
import { toggleModal } from 'components/modal';

import { useFirebase } from 'hooks/firebase';
import { useCategory } from 'hooks/category';
import { useLoading } from 'hooks/loading';

import { api } from 'services/api';

import { extractImageFileExtensionFromBase64 } from 'utils/toCropUtils';

import {
  ref,
  deleteObject,
  uploadBytes,
  getDownloadURL,
} from 'firebase/storage';

import { ref as dbRef, update } from 'firebase/database';

import { useLocation, useNavigate } from 'react-router-dom';

import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';

import { v4 } from 'uuid';

import {
  FaCamera,
  FaEye,
  FaEyeSlash,
  FaPlusSquare,
  FaRegSave,
} from 'react-icons/fa';
import { sortIt } from 'utils/specialchars';
import {
  Wrapper,
  LanguageContainer,
  LanguageContent,
  CategoryContainer,
  CategoryLabel,
  ButtonCreateCategory,
  DimensionContainer,
  VisibleContainer,
  PhotoContainer,
  SaveButton,
} from './styles';

import { ArtsProps } from '../main';

interface FormData {
  size: string;
  ptbrname: string;
  ptbrmaterial: string;
  ptbrtype: string;
  ptbrdescription: string;
  itname: string;
  itmaterial: string;
  ittype: string;
  itdescription: string;
  category: string[];
  visible: boolean;
}

const ArtsManagementUpdate: React.FC = () => {
  const { toggleLoading } = useLoading();
  const { categories, CreateCategoryForm } = useCategory();
  const { storage, database, db_env } = useFirebase();
  const [language] = useState('ptbr');

  const formRef = useRef<FormHandles>(null);
  const location = useLocation();
  const [reg] = useState<ArtsProps>({
    ...(location.state as ArtsProps),
  });
  const [oldImage] = useState<boolean>(() => {
    return !!reg.ref;
  });
  const [image, setImage] = useState(null);
  const [compressedImage, setCompressedImage] = useState(null);

  const [artCategories, setArtCategories] = useState<string[]>(() => {
    return reg.category.map((item) => item.value);
  });
  const [visible, setVisible] = useState(() => {
    return reg.visible;
  });

  const navigate = useNavigate();

  function bytesToSize(bytes: number) {
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];

    if (bytes === 0) {
      return '0 Byte';
    }

    const i = Math.floor(Math.log(bytes) / Math.log(1024));

    return `${(bytes / 1024 ** i).toFixed(2)} ${sizes[i]}`;
  }

  const handleVisibilityChange = useCallback(() => {
    setVisible((state) => !state);
  }, []);

  const handleFile = useCallback((file: Blob) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      const fileExtension = extractImageFileExtensionFromBase64(reader.result);

      const file2b = new File([file], 'amanda.jpeg', {
        type: `image/${fileExtension}`,
      });
      setCompressedImage(file2b);
    };
    reader.readAsDataURL(file);
  }, []);

  const preHandleFile = useCallback((blob: Blob) => {
    const file2b = new File([blob], 'prefile');

    const sreader = new FileReader();

    sreader.onloadend = () => {
      const original = document.querySelector('#resizedImage') as any;

      const imgOrig = new Image(600, 600);
      imgOrig.src = original.src;
      imgOrig.id = 'brandnew';

      imgOrig.onload = () => {
        const canvas = document.createElement('canvas');

        const context = canvas.getContext('2d');

        const canvasWidth = 600;
        const canvasHeight = 600;

        canvas.width = canvasWidth;
        canvas.height = canvasHeight;

        context.drawImage(imgOrig, 0, 0, canvasWidth, canvasHeight);

        canvas.toBlob(
          (nblob) => {
            if (nblob) {
              handleFile(blob);
            }
          },
          'image/jpeg',
          1,
        );
      };
    };

    sreader.readAsDataURL(file2b);
  }, []);

  const handlePhotoChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const file = event.target.files[0];

      const reader = new FileReader();

      reader.onload = (e) => {
        document
          .querySelector('#newImage')
          .setAttribute('src', e.target.result as string);

        document
          .querySelector('#originalImage')
          .setAttribute('src', e.target.result as string);
      };

      reader.onloadend = () => {
        const original = document.querySelector('#originalImage') as any;

        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');

        const canvasWidth = 1200;
        const canvasHeight = 1200;

        canvas.width = canvasWidth;
        canvas.height = canvasHeight;

        context.drawImage(original, 0, 0, canvasWidth, canvasHeight);

        canvas.toBlob(
          (blob) => {
            if (blob) {
              const nsrc = URL.createObjectURL(blob);
              document.querySelector('#resizedImage').setAttribute('src', nsrc);
              preHandleFile(blob);
            }
          },
          'image/jpeg',
          1,
        );
      };

      reader.readAsDataURL(file);

      setImage(file);
    },
    [],
  );

  const handleCheckbox = useCallback((category: string) => {
    setArtCategories((state) => {
      const filter = [...state];
      if (filter.includes('1')) {
        return [category];
      }

      if (filter.length === 1 && category === filter[0]) {
        return ['1'];
      }

      return state.indexOf(category) > -1
        ? state.filter((item) => item !== category)
        : [...state, category].sort((a, b) => (a > b ? 1 : b > a ? -1 : 0));
    });
  }, []);

  const handleSubmit = async (data: FormData) => {
    if (!!image && !!compressedImage) {
      console.log(
        `Original size: ${bytesToSize(
          image.size,
        )} / Compressed size: ${bytesToSize(compressedImage.size)}`,
      );
    }

    toggleLoading();

    const updates = {};
    const visibility = document.querySelector<HTMLInputElement>('#visible');

    if (!!oldImage && !!image) {
      const oldImageRef = ref(storage, `${db_env}/arts/${reg.ref}`);
      await deleteObject(oldImageRef).then(() => {
        updates[`arts/${reg.id}/ref`] = null;
        updates[`arts/${reg.id}/downloadURL`] = null;
      });
    }

    if (image) {
      const uuid = v4();

      const newImageRef = ref(storage, `${db_env}/arts/${uuid}`);
      const httpref = await uploadBytes(newImageRef, compressedImage).then(
        async (snapshot) => {
          return getDownloadURL(snapshot.ref).then((url) => url);
        },
      );

      updates[`${db_env}/arts/${reg.id}/ref`] = uuid;
      updates[`${db_env}/arts/${reg.id}/downloadURL`] = httpref;

      const upload = new FormData();
      upload.append('oldRef', reg.ref);
      upload.append('newRef', uuid);
      upload.append('newPhoto', image);
      upload.append('ext', image.type.replace('image/', ''));

      await api.post('/upload.php', upload, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
    }

    updates[`${db_env}/arts/${reg.id}/ptbr/name`] = data.ptbrname;
    updates[`${db_env}/arts/${reg.id}/ptbr/material`] = data.ptbrmaterial;
    updates[`${db_env}/arts/${reg.id}/ptbr/type`] = data.ptbrtype;
    updates[`${db_env}/arts/${reg.id}/ptbr/description`] = data.ptbrdescription;

    updates[`${db_env}/arts/${reg.id}/it/name`] = data.itname;
    updates[`${db_env}/arts/${reg.id}/it/material`] = data.itmaterial;
    updates[`${db_env}/arts/${reg.id}/it/type`] = data.ittype;
    updates[`${db_env}/arts/${reg.id}/it/description`] = data.itdescription;

    updates[`${db_env}/arts/${reg.id}/category`] = artCategories;
    updates[`${db_env}/arts/${reg.id}/size`] = data.size;
    updates[`${db_env}/arts/${reg.id}/visible`] =
      !image && !oldImage ? false : visibility.checked;

    await update(dbRef(database), updates)
      .then(() => {
        toggleLoading();
        navigate(-1);
      })
      .catch((e) => {
        alert(e);
      });
  };

  return (
    <Container modalcontent={<CreateCategoryForm />}>
      <Form
        ref={formRef}
        onSubmit={handleSubmit}
        initialData={{
          ptbrname: reg.ptbr.name,
          ptbrdescription: reg.ptbr.description,
          ptbrmaterial: reg.ptbr.material,
          ptbrtype: reg.ptbr.type,
          itname: reg.it.name,
          itdescription: reg.it.description,
          itmaterial: reg.it.material,
          ittype: reg.it.type,
          size: reg.size,
        }}
      >
        <Wrapper>
          <LanguageContainer>
            <LanguageContent>
              <strong>Português</strong>
              <div>
                <Input name="ptbrname" label="Nome" />
              </div>
              <div>
                <Textarea
                  label="Descrição"
                  name="ptbrdescription"
                  defaultValue={reg.ptbr.description}
                />
              </div>
              <div>
                <div>
                  <Input
                    name="ptbrmaterial"
                    defaultValue={reg.ptbr.material}
                    label="Material"
                  />
                </div>
                <div>
                  <Input
                    name="ptbrtype"
                    defaultValue={reg.ptbr.type}
                    label="Tipo"
                  />
                </div>
              </div>
            </LanguageContent>
            <div>
              <strong>Italiano</strong>
              <div>
                <Input name="itname" defaultValue={reg.it.name} label="Nome" />
              </div>
              <div>
                <Textarea
                  label="Descrição"
                  name="itdescription"
                  defaultValue={reg.it.description}
                />
              </div>
              <div>
                <div>
                  <Input
                    name="itmaterial"
                    defaultValue={reg.it.material}
                    label="Material"
                  />
                </div>
                <div>
                  <Input
                    name="ittype"
                    defaultValue={reg.it.type}
                    label="Tipo"
                  />
                </div>
              </div>
            </div>
          </LanguageContainer>
          <CategoryContainer>
            <strong>Categorias:</strong>
            <div>
              {sortIt(categories, 'ptbr').map((item) => (
                <CategoryLabel
                  key={item.id}
                  htmlFor={`input${item.ref}`}
                  data-display={item[language]}
                  marked={artCategories.includes(item.ref)}
                >
                  <input
                    type="checkbox"
                    onClick={() => handleCheckbox(item.ref)}
                    disabled={item.ref === '1'}
                    readOnly
                  />
                </CategoryLabel>
              ))}
              <CategoryLabel
                htmlFor="input1"
                data-display="Sem categoria"
                marked={artCategories.includes('1')}
                disabled
              >
                <input type="checkbox" disabled readOnly />
              </CategoryLabel>
              <ButtonCreateCategory onClick={toggleModal} type="button">
                <FaPlusSquare />
              </ButtonCreateCategory>
            </div>
          </CategoryContainer>

          <DimensionContainer>
            <Input name="size" defaultValue={reg.size} label="Dimensão" />
          </DimensionContainer>
          <VisibleContainer marked={visible}>
            <strong>Visível:</strong>
            <label htmlFor="visible">
              {visible ? <FaEye /> : <FaEyeSlash />}
              <input
                id="visible"
                name="visible"
                type="checkbox"
                defaultChecked={reg.visible}
                onChange={handleVisibilityChange}
              />
            </label>
          </VisibleContainer>
          <div id="compression-message" />
          <PhotoContainer>
            <div>
              {!!reg.downloadURL && (
                <div>
                  <p>Imagem atual:</p>
                  <img src={reg.downloadURL} alt="none" />
                </div>
              )}
            </div>
            <label htmlFor="photo">
              <FaCamera />
              <input
                id="photo"
                name="photo"
                type="file"
                accept="image/*"
                onChange={(event) => handlePhotoChange(event)}
              />
            </label>
            <div>
              {!!image && (
                <div>
                  <p>Nova Imagem:</p>
                  <img id="newImage" src="" alt="No Image" />
                </div>
              )}
            </div>
          </PhotoContainer>

          <SaveButton type="submit">
            <p>
              <FaRegSave />
            </p>
          </SaveButton>
        </Wrapper>
        <img
          id="originalImage"
          alt=""
          style={{ display: 'none' }}
          crossOrigin="anonymous"
        />
      </Form>
      <div>
        <img
          id="resizedImage"
          alt="none"
          crossOrigin="anonymous"
          style={{ display: 'none' }}
        />
      </div>
    </Container>
  );
};

export default ArtsManagementUpdate;
