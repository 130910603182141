import React, { createContext, useContext, useState, useCallback } from 'react';
import { AppProps } from 'interfaces';

interface LoadingHookData {
  toggleLoading: () => void;
  // setLoading: (value: boolean) => void;
  isLoading: boolean;
}

const LoadingHook = createContext<LoadingHookData>({} as LoadingHookData);

const LoadingProvider: React.FC<AppProps> = ({ children }) => {
  const [isLoading, setIsLoading] = useState(false);

  const toggleLoading = useCallback(() => {
    setIsLoading((state) => !state);
  }, []);

  return (
    <LoadingHook.Provider value={{ toggleLoading, isLoading }}>
      {children}
    </LoadingHook.Provider>
  );
};

function useLoading(): LoadingHookData {
  const context = useContext(LoadingHook);

  if (!context) {
    throw new Error('useLoading must be used within an LoadingHookProvider');
  }

  return context;
}
export { LoadingProvider, useLoading };
