import styled, { keyframes } from 'styled-components';
import { animated } from 'react-spring';
import { parseToRgb } from 'polished';

import br from 'assets/br.png';
import it from 'assets/it.png';

const enterSpan = keyframes`
  0%{
    opacity: 0;
    transform: translateX(-10px);
  }
  50%{
    transform: translateX(10px);
  }
  100%{
    opacity: 1;
    transform: translateX(0px);
  }
`;

const enterDiv = keyframes`
  0%{
    background: rgba(255, 255, 255, 0);
    box-shadow: 0px 0px 0px 3px rgba(255, 255, 255, 0);

  }
  100%{
    background: rgba(255, 255, 255, 0.5);
    box-shadow: 0px 0px 5px 3px rgba(255, 255, 255, 0.5);

  }
`;

const enterClick = keyframes`
  0%{
    opacity: 0;
  }
  100%{
    opacity: 1;
  }
`;

const DESTACARISSO = keyframes`
  0%{
    color: #591d3d;
    text-shadow: 0px 0px 0px rgba(255,255,255,0.25);
  }
  50%{
    text-shadow: 0px 0px 2px rgba(255,255,255,0.75);
    color: #872c5c;
  }
  100%{
    text-shadow: 0px 0px 0px rgba(255,255,255,0.25);
    color: #591d3d;
  }
`;

interface CustomLinkProps {
  country?: string;
}

export const ContentContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 20px 20px 0 20px;
  transition: 0.35s ease;

  @media screen and (max-width: 1366px) {
    flex-direction: column;
    /* padding: 0px; */
  }
`;
export const ImageContainer = styled(animated.div)`
  margin: 0 auto;

  display: grid;
  justify-items: center;
  align-content: flex-start;
  padding: 0 0 2rem 0;

  figure {
    overflow: hidden;
    padding-block-start: 6rem;
    border-radius: 0 0 9999px 9999px;
    transform: scale(var(--scale));

    transition: 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    transform-origin: 50% calc(50% + 4rem);
    will-change: transform;

    > div {
      --bordercolor: #52001e;
      --divred: ${parseToRgb('#52001e').red};
      --divgreen: ${parseToRgb('#52001e').green};
      --divblue: ${parseToRgb('#52001e').blue};
      display: grid;
      place-content: center;
      border-radius: 9999px;
      border: 2px solid var(--bordercolor);
      width: 400px;
      height: 400px;
      background: transparent;

      background: radial-gradient(
        rgba(var(--divred), var(--divgreen), var(--divblue), 0.15),
        rgba(var(--divred), var(--divgreen), var(--divblue), 0.25),
        rgba(var(--divred), var(--divgreen), var(--divblue), 0.25),
        rgba(var(--divred), var(--divgreen), var(--divblue), 0.25),
        rgba(var(--divred), var(--divgreen), var(--divblue), 0.55),
        rgba(var(--divred), var(--divgreen), var(--divblue), 1)
      );
      /* background: conic-gradient(
          at 125% 50%,
          #b78cf7,
          #ff7c94,
          #ffcf0d,
          #ff7c94,
          #b78cf7
        )
        border-box; */
      box-shadow: inset 0 -2px 0 1px #0001;
    }

    img {
      justify-self: center;
      width: 90%;
      max-width: none;
      object-fit: cover;
      transform: translateY(var(--translateY, 5%));
      transition: 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
      filter: var(--ds);
      will-change: transform, filter;
    }

    &:hover {
      --scale: 1.15;
      --translateY: -4%;
      --ds: drop-shadow(2px 5px 5px #0004);
    }
  }

  @media screen and (max-width: 1366px) {
    figure {
      padding-block-start: 2rem;
    }
  }
  @media screen and (max-width: 970px) {
    figure {
      padding-block-start: 4rem;
      > div {
        width: 350px;
        height: 350px;
      }
      img {
        width: 400px;
      }
    }
  }

  @media screen and (max-width: 700px) {
    figure {
      padding-block-start: 3.5rem;
      > div {
        width: 180px;
        height: 180px;
      }
      img {
        width: 100%;
      }

      &:hover {
        --scale: 1.1;
        --translateY: -12%;
      }
    }
  }
  @media screen and (max-width: 450px) {
    figure {
      pointer-events: none;
      --scale: 1.1;
      --translateY: -11%;
    }
  }
`;

export const MessageContainer = styled.div`
  --fontsize: 12;
  margin: 7rem 0 0 0;
  margin-right: auto;

  @media screen and (max-width: 1366px) {
    margin: 0;
  }
`;

export const MessageContent = styled.div`
  padding: 10px;
  border-radius: 5px;

  background: rgba(255, 255, 255, 0);
  box-shadow: 0px 0px 0px 3px rgba(255, 255, 255, 0);
  animation: ${enterDiv} 2s forwards;
  animation-delay: 0.4s;

  span {
    display: flex;

    opacity: 0;
    transform: translateX(-40px);
    animation: ${enterSpan} 2s forwards;
    height: unset;

    p {
      font-family: 'Shadows Into Light', cursive;
      font-size: calc(var(--fontsize) * 3px);
      color: #591d3d;
      text-shadow: 0px 0px 2px rgba(0, 0, 0, 0.5);
    }

    &:nth-child(1) {
      animation-delay: 0.5s;
    }
    &:nth-child(2) {
      animation-delay: 0.7s;
    }
    &:nth-child(3) {
      animation-delay: 0.9s;
    }
    &:nth-child(4) {
      animation-delay: 1.1s;
    }
    &:nth-child(5) {
      p {
        font-family: 'Dancing Script', cursive;
        animation: ${DESTACARISSO} 4s linear infinite;
        font-size: calc(var(--fontsize) * 4px);
        font-weight: bold;
      }
      justify-content: center;
      animation-delay: 1.3s;
    }

    & + span {
      margin: 15px 0 0 0;
    }
  }

  @media screen and (max-width: 1500px) {
    --fontsize: 10;
  }
  @media screen and (max-width: 1366px) {
    margin: 0.5rem 0 0 0;
  }

  @media screen and (max-width: 700px) {
    --fontsize: 8;
  }
  @media screen and (max-width: 400px) {
    --fontsize: 6;
  }
`;

export const NavigateContainer = styled.div`
  display: flex;
  /* background: lime; */
  /* justify-content: flex-end; */
  --fontsize: 12;
  margin: 1rem 0 3rem 0;

  /* padding-right: 20px; */

  div {
    padding: 10px;
    border-radius: 5px;

    background: rgba(255, 255, 255, 0.5);
    box-shadow: 0px 0px 5px 3px rgba(255, 255, 255, 0.5);

    /* opacity: 1; */
    /* margin-right: 8.4rem; */
    margin: 15px auto 0;
    opacity: 0;
    animation: ${enterClick} 2s forwards;
    animation-delay: 0.4s;

    font-family: 'Shadows Into Light', cursive;
    font-size: calc(var(--fontsize) * 3px);
    color: #591d3d;
    text-shadow: 0px 0px 2px rgba(0, 0, 0, 0.5);

    a {
      color: white;
      text-decoration: none;
    }

    @media screen and (max-width: 1500px) {
      --fontsize: 10;
    }

    @media screen and (max-width: 700px) {
      --fontsize: 8;
    }
    @media screen and (max-width: 400px) {
      --fontsize: 6;
    }
  }
`;

export const ContactsContainer = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  margin: auto 0 0;
  height: 100%;
  min-height: 60px;
  background: transparent;
  padding: 0 35px 10px 0;
`;

export const StyledLink = styled.a<CustomLinkProps>`
  --svgsize: 50px;
  position: relative;
  color: #fff;
  display: flex;
  cursor: pointer;

  svg {
    opacity: 0.5;
    color: #fff;
    height: var(--svgsize);
    width: var(--svgsize);

    transition: opacity 0.25s;
    will-change: opacity, color;
  }

  &:after {
    /* content: url(${br}); */
    content: ${(props) =>
      props.country ? `url('${props.country === 'br' ? br : it}')` : ''};
    position: absolute;
    top: 0;
    left: -10px;
    /* color: #fff;
    font-size: 18px;
    font-weight: bold;
    will-change: right;

    transition: left 0.25s; */
    /* -webkit-text-stroke: 1px #efefef;*/
    /* better with bigger fonts */
    /* text-shadow: 0px 0px 1px rgba(255, 0, 0, 1); */
  }
  & + a {
    margin: 0 0 0 25px;
  }

  &:hover {
    &:after {
      /* left: -25%; */
    }

    svg {
      opacity: 1;
      /* color: #25d366; */
    }
  }

  @media screen and (max-width: 700px) {
    --svgsize: 35px;
  }
  @media screen and (max-width: 400px) {
    --svgsize: 27px;
  }
`;
