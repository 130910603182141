import styled from 'styled-components';
import { animated } from 'react-spring';

// const colorA = '#464646';
// const colorB = '#262626';

interface WrapperProps {
  gradient: {
    primary: string;
    secondary: string;
  };
}

export const Wrapper = styled.div<WrapperProps>`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  width: 100%;
  height: 100%;
  overflow: hidden;
  /* background-image: linear-gradient(to bottom, #fecdd3, #f43f5e); */

  /* background-image: url(${process.env
    .REACT_APP_BACKGROUND}),linear-gradient(to bottom, #fecdd3, #f43f5e); */
  /* background-image: url(${process.env.REACT_APP_BACKGROUND}),
    linear-gradient(to bottom, #fba2bd, #f63770); */
  background-image: url(${process.env.REACT_APP_BACKGROUND}),
    linear-gradient(
      to bottom,
      ${(p) => p.gradient.primary},
      ${(p) => p.gradient.secondary}
    );
`;

// export const Background = styled.div<BackgroundProps>`
//   z-index: 1;
//   position: absolute;
//   top: 0;
//   left: 0;
//   width: 100%;
//   height: 100%;
//   opacity: 0.15;
//   background-color: transparent;
//   /* background-color: lime; */

//   ${(props) =>
//     !!props.pic &&
//     css`
//       background-image: url(${props.pic});
//     `}
// `;

export const Content = styled.div`
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  /* height: 100vh; */
  width: 100%;

  /* background-color: #fecdd3; */

  ::-webkit-scrollbar {
    display: none;
  }
`;

export const MainNavbar = styled.nav`
  --color: #fff;

  z-index: 3;
  position: sticky;
  top: 0px;
  display: flex;
  width: 100vw;
  max-height: 50px;
  min-height: 50px;
  background: #2d2d2d;
  overflow: visible;
  box-shadow: 0px 0px 10px 15px rgba(0, 0, 0, 0.5);

  > div {
    position: absolute;
    /* top: 50%; */
    /* transform: translateY(-50%); */
  }

  button {
    background: transparent;
    border: 0px;
    color: var(--color);
  }
  a {
    text-decoration: none;
    color: var(--color);
  }
  svg {
    color: var(--color);
    height: 30px;
    width: 30px;
  }
`;

export const LanguageButton = styled.div`
  left: 5px;
  top: 50%;
  transform: translateY(-50%);

  button {
    background: transparent;
    border: 0px;
  }
`;
export const HomePageButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;

  left: calc(50vw - 25px);
`;
export const Navigation = styled.div`
  position: absolute;

  right: 5px;
  overflow: visible;

  display: flex;
  flex-direction: column;

  > button {
    background: transparent;
    border: 0px;
    z-index: 4;
    font-size: 35px;
  }

  > div {
    margin: 50% 10px 0 0;
    transform: translateY(-35%);
  }
  figure {
    display: flex;
    background: transparent;
    opacity: 0;
    position: absolute;
    width: calc(100vw - 450px);
    height: 100vh;
    top: 0px;
    left: -100vw;
  }
  nav {
    display: flex;
    flex-direction: column;
    overflow: hidden;

    background: #262626;
    position: absolute;
    top: -1.5px;
    right: -5px;

    height: 100vh;
    /* transition: width 0.35s ease; */
    box-shadow: -10px 0px 10px 0px rgba(38, 38, 38, 0.5);
  }
`;
export const NavigationNav = styled(animated.nav)`
  position: relative;
  padding: 50px 30px;
  div {
    white-space: nowrap;
    button {
      font-size: 30px;
    }

    > p {
      margin: 15px 0 0 0;
      color: #fff;
      font-size: 30px;
    }
    /* background: yellow; */
  }
`;

export const AdminContainer = styled.div`
  position: absolute;
  bottom: 150px;
`;
