import styled, { css } from 'styled-components';

interface InputProps {
  hidden: boolean;
}

interface LabelProps {
  focused: boolean;
  filled: boolean;
}

export const InputContainer = styled.div<InputProps>`
  display: flex;
  align-items: flex-end;
  padding: 0px;

  height: 80px;
  /* background: white; */

  ${(props) =>
    props.hidden &&
    css`
      display: none;
    `}
`;

export const Label = styled.label<LabelProps>`
  position: relative;
  display: flex;
  padding: 5px;
  width: 100%;
  height: 50px;

  &:before {
    color: #767676;
    font-size: 20px;
    content: attr(data-display-name);
    pointer-events: none;
    position: absolute;
    top: 50%;
    left: 10px;
    transform: translateY(-50%);

    transition: top 0.35s, color 0.35s;
    will-change: top, color;
  }

  input {
    width: 100%;
    font-size: 18px;

    padding-left: 5px;
    border: 0;
    background: transparent;
    border-bottom: solid 2px #a6a6a6;

    transition: border-color 0.35s;
    will-change: border-color;
  }

  ${(props) =>
    props.focused &&
    css`
      border-color: #464646;
      &:before {
        color: #f06292;
        top: -8px;
      }

      input {
        border-color: #f06292;
      }
    `}

  ${(props) =>
    props.filled &&
    css`
      &:before {
        top: -8px;
      }

      input {
        border-color: #f06292;
      }
    `}
`;
