import React, { useCallback, useState, useEffect } from 'react';
import { AppProps } from 'interfaces';

import FullLoading from 'components/fullLoading';

import { useLocation } from 'react-router-dom';
import { useFirebase } from 'hooks/firebase';
import { useStyle } from 'hooks/style';
import { useLang } from 'hooks/language';

import { Trans } from 'react-i18next';

import { FaHome } from 'react-icons/fa';
import { useSpring, animated } from 'react-spring';
import { useWindow } from 'hooks/window';
import Navigator from 'components/navigator';
import Burger from 'components/burger';
import Modal from 'components/modal';

import br from 'assets/br.png';
import it from 'assets/it.png';
import logo from 'assets/apzlogo.png';

import {
  Wrapper,
  Content,
  // Background,
  MainNavbar,
  HomePageButton,
  Navigation,
  AdminContainer,
  NavigationNav,
  LanguageButton,
} from './styles';

export interface ContainerProps extends AppProps {
  usenav?: boolean;
  modalcontent?: React.ReactNode;
}

const Container: React.FC<ContainerProps> = ({
  children,
  usenav = true,
  modalcontent,
}) => {
  const { gradient } = useStyle();
  const { userpresent } = useFirebase();
  const { width } = useWindow();
  const [navOpen, setNavOpen] = useState(false);
  const { pathname } = useLocation();
  const { setLang } = useLang();
  // const [categories, setCategories] = useState<CategoriesProps[]>([]);

  // const getCategories = useCallback(async () => {
  //   try {
  //     const response = await api.get('/common/categories.php');

  //     setCategories(response.data);
  //   } catch (err) {
  //     //
  //   }
  // }, []);

  const handleToggleNav = useCallback(() => {
    // event.stopPropagation();
    setNavOpen((state) => !state);
  }, []);

  const toggleBurger = useCallback(() => {
    const cb: any = document.getElementById('burger-checkbox');
    cb.click();
  }, []);

  const styledNav = useSpring({
    // right: navOpen ? '-5px' : width < 650 ? '-660px' : '-510px',
    width: navOpen ? (width <= 650 ? '360px' : '500px') : '0px',

    opacity: navOpen ? 1 : 0,
  });

  useEffect(() => {
    // getCategories();
  }, []);

  return (
    <Wrapper gradient={{ ...gradient }}>
      <FullLoading />
      {/* <Background pic={`${process.env.REACT_APP_BACKGROUND}`} /> */}
      <Modal>{modalcontent}</Modal>
      <Content>
        {usenav ? (
          <MainNavbar>
            <LanguageButton>
              <img
                src={logo}
                style={{ height: '35px', objectFit: 'contain' }}
                alt="APZ"
              />
            </LanguageButton>
            <HomePageButton>
              <Navigator route="home" navController={false}>
                <FaHome />
              </Navigator>
            </HomePageButton>
            <Navigation>
              <Burger callbackfunction={handleToggleNav} />

              <animated.figure
                style={{
                  pointerEvents: navOpen ? 'all' : 'none',
                }}
                onClick={toggleBurger}
              />
              <NavigationNav
                style={{
                  pointerEvents: navOpen ? 'all' : 'none',
                  ...styledNav,
                }}
              >
                {pathname.includes('/admin') ? (
                  <>
                    <div>
                      <Navigator route="admin">Menu</Navigator>
                    </div>
                    <div>
                      <Navigator route="admin/profile">Meu perfil</Navigator>
                    </div>
                    <div>
                      <Navigator route="admin/arts">Gerenciar artes</Navigator>
                    </div>
                    <div>
                      <Navigator route="admin/categories">
                        Gerenciar categorias
                      </Navigator>
                    </div>
                  </>
                ) : (
                  <div>
                    <Navigator route="gallery">
                      {/* Galeria */}
                      <Trans i18nKey="navigation.gallery" />
                    </Navigator>
                    <p>
                      <Trans i18nKey="navigation.language.label" />
                    </p>
                    <div>
                      <button
                        type="button"
                        style={{ margin: '0 15px 0 0' }}
                        onClick={() => setLang('pt-BR')}
                      >
                        <img src={br} alt="PTBR" />
                      </button>
                      <button type="button" onClick={() => setLang('it')}>
                        <img src={it} alt="IT" />
                      </button>
                    </div>
                    {/* <div data-type="language-container">
                      <h3>
                        <Trans i18nKey="navigation.language.label" />
                      </h3>
                      <div>
                        <button type="button">
                          <Trans i18nKey="navigation.language.portuguese.label" />
                        </button>
                      </div>
                      <div>
                        <button type="button">
                          <Trans i18nKey="navigation.language.italian.label" />
                        </button>
                      </div>
                    </div> */}
                  </div>
                )}

                {!pathname.includes('/admin') && userpresent ? (
                  <AdminContainer>
                    <Navigator route="admin">Admin</Navigator>
                  </AdminContainer>
                ) : null}
                {/* {categories.map((item) => (
                <React.Fragment key={item.id}>
                  {item.id >= 100 && (
                    <Navigator route={`gallery/${item.route}`}>
                      {item.category}
                    </Navigator>
                  )}
                </React.Fragment>
              ))}
              <div>
                <p style={{ color: '#ffffff' }}>
                  TODO: move to hook or localStorage
                </p>
              </div> */}
              </NavigationNav>
            </Navigation>
          </MainNavbar>
        ) : null}
        {children}
      </Content>
    </Wrapper>
  );
};

export default Container;
