import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import portuguese from 'locales/portuguese.json';
import italian from 'locales/italian.json';
// import portuguese from './locales/portuguese/lang.json';
// import english from './locales/english/lang.json';
// import italian from './locales/italian/lang.json';

const resources = {
  pt: {
    translation: portuguese,
  },

  it: {
    translation: italian,
  },
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: 'it',
    fallbackLng: 'it',

    // keySeparator: false,

    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
