import React from 'react';

import { AppProps } from 'interfaces';
import { LanguageProvider } from './language';
import { StyleProvider } from './style';
import { FirebaseProvider } from './firebase';
import { LocalStorageProvider } from './localstorage';
import { LoadingProvider } from './loading';
import { CategoryProvider } from './category';
import { WindowProvider } from './window';

const AppProvider: React.FC<AppProps> = ({ children }) => {
  return (
    <LanguageProvider>
      <StyleProvider>
        <FirebaseProvider>
          <LocalStorageProvider>
            <LoadingProvider>
              <CategoryProvider>
                <WindowProvider>{children}</WindowProvider>
              </CategoryProvider>
            </LoadingProvider>
          </LocalStorageProvider>
        </FirebaseProvider>
      </StyleProvider>
    </LanguageProvider>
  );
};

export default AppProvider;
