import React, { ButtonHTMLAttributes, useCallback } from 'react';

import { useNavigate, useLocation } from 'react-router-dom';

import { Button } from './styles';

interface NavigatorProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  customStyle?: object;
  route: string;
  navController?: boolean;
}

const Navigator: React.FC<NavigatorProps> = ({
  children,
  route,
  customStyle = {},
  navController = true,
  ...rest
}) => {
  const history = useNavigate();
  const { pathname } = useLocation();

  function formatString(param: string): string {
    return param.replace(/\//g, '').trim();
  }
  const handleNavigation = useCallback(() => {
    const btn = document.getElementById('navController');
    if (formatString(pathname) !== formatString(route)) {
      if (btn && navController) {
        btn.click();
      }
      history(`/${route}`);
    }
  }, []);

  return (
    <Button
      type="button"
      onClick={handleNavigation}
      style={customStyle}
      {...rest}
    >
      {children}
    </Button>
  );
};

export default Navigator;
