import React, { useEffect, useState } from 'react';

import Container from 'components/container';
import { useFirebase } from 'hooks/firebase';

import { Trans } from 'react-i18next';
// import { useCategory } from 'hooks/category';

import { Link } from 'react-router-dom';

import {
  getStorage,
  ref,
  list,
  getMetadata,
  getDownloadURL,
} from 'firebase/storage';

import { FaInstagram, FaWhatsapp } from 'react-icons/fa';
import {
  ContentContainer,
  ImageContainer,
  MessageContainer,
  MessageContent,
  NavigateContainer,
  ContactsContainer,
  StyledLink,
} from './styles';

const HomePage: React.FC = () => {
  const { db_env } = useFirebase();
  // const { categories } = useCategory();

  const [image, setImage] = useState(() => {
    const stored = localStorage.getItem(`homeimage${db_env}`);
    return stored || '';
  });

  useEffect(() => {
    const storage = getStorage();
    const imageListRef = ref(storage, `${db_env}/home`);

    list(imageListRef).then((response) => {
      response.items.forEach((item) => {
        getMetadata(item).then(async (url) => {
          const stored = localStorage.getItem(`homeimage${db_env}`);
          const source = await getDownloadURL(url.ref).then((src) => src);
          if (!stored || (!!stored && stored !== source)) {
            setImage(source);
            localStorage.setItem(`homeimage${db_env}`, source);
          }

          // console.table({ name: url.name, url: source });
        });
      });
    });
  }, []);

  return (
    <Container>
      <ContentContainer>
        <ImageContainer>
          <figure>
            <div>{image ? <img src={image} alt="" /> : null}</div>
          </figure>
        </ImageContainer>
        <MessageContainer>
          <MessageContent>
            <span>
              <p>
                <Trans i18nKey="home.message.one" />
                {/* Aqui você vai encontrar as minhas criações. Meus letterings. */}
              </p>
            </span>
            <span>
              <p>
                <Trans i18nKey="home.message.two" />
                {/* Eu amo &ldquo;lettering&rdquo; porque as palavras tem poder. */}
              </p>
            </span>
            <span>
              <p>
                <Trans i18nKey="home.message.three" />
                {/* É uma ordem que pode ser usada tanto pra criação como pra destruição. */}
              </p>
            </span>
            <span>
              <p>
                <Trans i18nKey="home.message.four" />
                {/* Que possamos usar todo esse PODER de um modo bom, para o bem! */}
              </p>
            </span>
            <span>
              <p>
                <Trans i18nKey="home.message.five" />
                {/* &ldquo;O bem sempre vence o mal&rdquo; */}
              </p>
            </span>
          </MessageContent>

          <NavigateContainer>
            <div>
              <p>
                {/* Veja minhas artes clicando <Link to="/gallery">aqui</Link> */}
                <Trans i18nKey="link.message" />
                <Link to="/gallery">
                  <Trans i18nKey="link.here" />
                </Link>
              </p>
            </div>
          </NavigateContainer>
        </MessageContainer>
      </ContentContainer>

      {/* <NavigateContainer>
        <div>
          <p>
            Veja minhas artes clicando <Link to="/gallery">aqui</Link>
          </p>
        </div>
      </NavigateContainer> */}

      {/* <div
        style={{ display: 'flex', width: '100vw', justifyContent: 'center' }}
      >
        Rede Social
      </div> */}
      <ContactsContainer>
        <StyledLink
          country="br"
          href="https://api.whatsapp.com/send?phone=+393519012821&text=Olá!%0aVisitei seu site e gostaria de informações sobre suas artes"
          target="_blank"
          rel="noopener noreferrer"
        >
          {/* <img
            src="https://flagcdn.com/80x60/br.png"
            width="80"
            height="60"
            alt="Brazil"
          /> */}
          <FaWhatsapp />
          {/* <span style={{ height: '50px', width: '50px' }} /> */}
        </StyledLink>
        <StyledLink
          country="it"
          href="https://api.whatsapp.com/send?phone=+393519012821&text=Ciao!%0aHo visitato il tuo sito web e vorrei informazioni sulle tue arti"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FaWhatsapp />
        </StyledLink>
        <StyledLink
          href="https://www.instagram.com/man_apz"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FaInstagram />
        </StyledLink>
      </ContactsContainer>
    </Container>
  );
};

export default HomePage;
