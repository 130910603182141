import React, { useCallback } from 'react';
import { FaGrinBeamSweat, FaShare } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';

import * as N from './styles';

const NotFound: React.FC = () => {
  const navigate = useNavigate();

  const handleGoBack = useCallback(() => {
    navigate(-1);
  }, []);
  return (
    <N.NotFoundContainer pic="https://amanda.pazinatoti.com.br/assets/404.png">
      <N.NotFoundContent>
        <N.Message>
          <p>
            Ops! <FaGrinBeamSweat />
          </p>
          <p>Não foi possível encontrar a página que está tentando acessar.</p>
          <p>Clique abaixo para voltar.</p>
          <button type="button" onClick={handleGoBack}>
            <span>
              <FaShare />
            </span>
            {/* <span>
              <Trans i18nKey="404page.goback" />
            </span> */}
          </button>
        </N.Message>
      </N.NotFoundContent>
    </N.NotFoundContainer>
  );
};

export default NotFound;
