import React, {
  createContext,
  useContext,
  useEffect,
  useState,
  useCallback,
} from 'react';

import { AppProps } from 'interfaces';

import { useNavigate } from 'react-router-dom';

import { initializeApp } from 'firebase/app';
import {
  getAuth,
  // onAuthStateChanged,
  browserLocalPersistence,
  signInWithEmailAndPassword,
  setPersistence,
  UserCredential,
} from 'firebase/auth';
import { getDatabase, Database, onValue, ref } from 'firebase/database';
import {
  getStorage,
  FirebaseStorage,
  connectStorageEmulator,
} from 'firebase/storage';

import { useStyle } from './style';

interface UserContact {
  content: string;
  display: boolean;
  type: string;
}

export interface User {
  uuid: string;

  contact: UserContact[];
  mail: string;
  name: string;
  preferences: {
    style: {
      bgGradient: {
        primary: string;
        secondary: string;
      };
    };
  };
  surname: string;
}

interface FirebaseHookData {
  // firebaseUser: FirebaseUser;
  db_env: string;
  user: User;
  userpresent: boolean;
  database: Database;
  storage: FirebaseStorage;
  signIn: (email: string, password: string) => void;
  signOut: () => void;
}

const FirebaseHook = createContext<FirebaseHookData>({} as FirebaseHookData);

const FirebaseProvider: React.FC<AppProps> = ({ children }) => {
  const { changeUserBGGradientColors } = useStyle();
  const navigate = useNavigate();
  const [user, setUser] = useState<User>(() => {
    const locallyStored = localStorage.getItem(`${process.env.REACT_APP_USER}`);

    return JSON.parse(locallyStored) || ({} as User);
  });

  // const signIn = useCallback(() => {}, []);
  // TODO:
  // sign in
  // sign out
  // authchange (persistance)
  // send email
  // forgot password

  const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
    databaseURL: process.env.REACT_APP_FIREBASE_DATABASE,
  };

  const db_env = process.env.REACT_APP_FIREBASE_DATABASE_PATH;
  const app = initializeApp(firebaseConfig);
  const auth = getAuth(app);

  const database = getDatabase(app);
  const storage = getStorage(app);

  if (process.env.REACT_APP_FIREBASE_USE_STORAGE === 'yes') {
    connectStorageEmulator(storage, 'localhost', 3502);
  }

  const signIn = async (email: string, password: string) => {
    await setPersistence(auth, browserLocalPersistence).then(async () => {
      await signInWithEmailAndPassword(auth, email, password).then(
        (credentials): UserCredential => {
          const retrieveUserFromDB = ref(
            database,
            `${db_env}/users/${credentials.user.uid}`,
          );
          onValue(retrieveUserFromDB, (snapshot) => {
            const data = snapshot.val();
            const preUser = { ...data } as User;
            setUser({ ...preUser });
            if (preUser.preferences.style.bgGradient) {
              changeUserBGGradientColors({
                ...preUser.preferences.style.bgGradient,
              });
            }
            localStorage.setItem(
              `${process.env.REACT_APP_USER}`,
              JSON.stringify({ ...data }),
            );
          });
          return credentials;
        },
      );

      // return signed;
    });
  };

  const signOut = useCallback(() => {
    // TODO: change for defualt BG
    changeUserBGGradientColors(JSON.parse(process.env.REACT_APP_BG_GRADIENT));
    setUser({} as User);
    localStorage.removeItem(`${process.env.REACT_APP_USER}`);
    localStorage.removeItem(`${process.env.REACT_APP_USER_INFO}`);
    navigate('/home');
  }, []);

  useEffect(() => {
    // onAuthStateChanged(auth, (retrivied) => {
    //   if (retrivied) {
    //     // get from DB
    //   } else {
    //     // TODO: toast
    //   }
    // });
  }, []);

  return (
    <FirebaseHook.Provider
      value={{
        signIn,
        signOut,
        user,
        db_env,
        // user: __user,
        // userpresent: userPresent,
        userpresent: Object.keys(user).length > 0,
        database,
        storage,
      }}
    >
      {children}
    </FirebaseHook.Provider>
  );
};

function useFirebase(): FirebaseHookData {
  const context = useContext(FirebaseHook);

  if (!context) {
    throw new Error('useFirebase must be used within an FirebaseHookProvider');
  }

  return context;
}

export { FirebaseProvider, useFirebase };
