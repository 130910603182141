import React, { useCallback, useState } from 'react';
import { FaPlus } from 'react-icons/fa';

import { useSpring } from 'react-spring';
import { Trans } from 'react-i18next';
import { CardContainer, Card, CardFace, InfoButton } from './styles';

interface CardProps {
  children?: React.ReactNode;
  front?: {
    content?: React.ReactNode;
    background?: string;
  };
  back?: {
    content?: React.ReactNode;
    background?: string;
  };
}
const CardComponent: React.FC<CardProps> = ({
  children,
  front = {},
  back = {},
  ...rest
}) => {
  const [face, toggleFace] = useState(false);

  const handleFaceToggle = useCallback(() => {
    toggleFace((state) => !state);
  }, []);

  const faceA = useSpring({
    opacity: !face ? 1 : 0.1,
  });
  const faceB = useSpring({
    opacity: face ? 1 : 0,
  });

  return (
    <CardContainer {...rest}>
      <Card>
        <CardFace style={{ ...faceA }} background={front.background}>
          {front.content}
        </CardFace>
        <CardFace style={{ ...faceB }} background={back.background}>
          {back.content}
        </CardFace>
        <InfoButton open={face} type="button" onClick={handleFaceToggle}>
          <FaPlus /> <Trans i18nKey="gallery.card.info" />
        </InfoButton>
      </Card>
    </CardContainer>
  );
};

export default CardComponent;
