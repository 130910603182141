import React, { useState, useRef, useCallback } from 'react';

import { useCategory } from 'hooks/category';
import { useFirebase } from 'hooks/firebase';

import Container from 'components/container';
import Input from 'components/input';

import { useLocation, useNavigate } from 'react-router-dom';

import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';

import { FaSave } from 'react-icons/fa';
import { CategoryProps, FormData as MainFormData } from '../main';
import { SaveButton, Wrapper } from './styles';

interface FormData extends MainFormData {
  id: string;
}

const CategoriesManagementUpdate: React.FC = () => {
  const { updateCategory } = useCategory();
  const { db_env } = useFirebase();

  const formRef = useRef<FormHandles>(null);
  const location = useLocation();
  const navigate = useNavigate();

  const [reg] = useState<CategoryProps>({
    ...(location.state as CategoryProps),
  });

  const handleSubmit = useCallback(async (data: FormData) => {
    const updates = {};
    updates[`${db_env}/categories/${data.id}/ptbr`] = data.ptbr;
    updates[`${db_env}/categories/${data.id}/it`] = data.it;

    updateCategory(updates, { onSuccess: () => navigate(-1) });
  }, []);

  return (
    <Container>
      <Form
        ref={formRef}
        onSubmit={handleSubmit}
        initialData={{ ptbr: reg.ptbr, it: reg.it }}
      >
        <Wrapper>
          <Input name="id" value={reg.id} disabled isHidden />
          <div>
            <Input name="ptbr" type="text" label="Nome da Categoria (PTBR):" />
          </div>
          <div>
            <Input name="it" type="text" label="Nome da Categoria (IT):" />
          </div>
        </Wrapper>
        <SaveButton type="submit">
          <FaSave />
        </SaveButton>
      </Form>
    </Container>
  );
};

export default CategoriesManagementUpdate;
