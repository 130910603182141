import React, { createContext, useContext, useState, useCallback } from 'react';

import { AppProps } from 'interfaces';
import { getDatabase, ref, update } from 'firebase/database';

interface GradientProps {
  primary: string;
  secondary: string;
}

interface StyleHookData {
  gradient: GradientProps;

  changeUserBGGradientColors: (obj) => void;
  applyDefaultBGGradientColors: () => void;
  commitUserBGGradientColors: (obj) => void;
}

const StyleHook = createContext<StyleHookData>({} as StyleHookData);

const StyleProvider: React.FC<AppProps> = ({ children }) => {
  const db_env = process.env.REACT_APP_FIREBASE_DATABASE_PATH;
  const [user] = useState(() => {
    const locallyStored = localStorage.getItem(`${process.env.REACT_APP_USER}`);

    return JSON.parse(locallyStored) || {};
  });
  const [userGradient, setUserGradient] = useState<GradientProps>(() => {
    if (Object.keys(user).length === 0) {
      return JSON.parse(process.env.REACT_APP_BG_GRADIENT) as GradientProps;
    }

    return user.preferences.style.bgGradient as GradientProps;
  });
  // primary: '#f9b9db',
  // secondary: '#591d3d',
  // primary: '#fba2bd',
  // secondary: '#f43f5e',
  // primary: '#464646',
  // secondary: '#262626',
  // primary: '#3d07cf',
  // secondary: '#db4d00',
  // primary: '#ff0095',
  // secondary: '#00b4cc',

  const changeUserBGGradientColors = useCallback((obj: GradientProps) => {
    setUserGradient({ ...obj });
  }, []);

  const commitUserBGGradientColors = useCallback(async (obj: GradientProps) => {
    const database = getDatabase();
    const userRef = ref(
      database,
      `${db_env}/users/${user.uuid}/preferences/style/`,
    );

    const updates = {};
    updates['bgGradient/primary'] = obj.primary;
    updates['bgGradient/secondary'] = obj.secondary;

    await update(userRef, updates);
    setUserGradient({ ...obj });

    user.preferences.style.bgGradient.primary = obj.primary;
    user.preferences.style.bgGradient.secondary = obj.secondary;

    localStorage.setItem(`${process.env.REACT_APP_USER}`, JSON.stringify(user));
  }, []);

  const applyDefaultBGGradientColors = useCallback(async () => {
    const database = getDatabase();
    const thisBG = JSON.parse(process.env.REACT_APP_BG_GRADIENT);

    const userRef = ref(
      database,
      `${db_env}/users/${user.uuid}/preferences/style/`,
    );

    const updates = {};
    updates['bgGradient/primary'] = thisBG.primary;
    updates['bgGradient/secondary'] = thisBG.secondary;

    await update(userRef, updates);
    setUserGradient({ ...thisBG });

    user.preferences.style.bgGradient.primary = thisBG.primary;
    user.preferences.style.bgGradient.secondary = thisBG.secondary;

    localStorage.setItem(`${process.env.REACT_APP_USER}`, JSON.stringify(user));
  }, [user]);

  return (
    <StyleHook.Provider
      value={{
        gradient: userGradient,
        changeUserBGGradientColors,
        applyDefaultBGGradientColors,
        commitUserBGGradientColors,
      }}
    >
      {children}
    </StyleHook.Provider>
  );
};

function useStyle(): StyleHookData {
  const context = useContext(StyleHook);

  if (!context) {
    throw new Error('useStyle must be used within an StyleHookProvider');
  }

  return context;
}

export { StyleProvider, useStyle };
