import React, { useEffect } from 'react';

import Container from 'components/container';

import { useFirebase } from 'hooks/firebase';

import { Link } from 'react-router-dom';
import { FaListUl, FaPaintBrush, FaPowerOff, FaUserCog } from 'react-icons/fa';
import { Grid, GridItem } from './styles';

const Management: React.FC = () => {
  const { signOut } = useFirebase();

  useEffect(() => {
    // const retrieveCategories = ref(database, 'categories/');
    // onValue(retrieveCategories, (snapshot) => {
    //   const data = snapshot.val();
    //   if (data) {
    //     setCats(
    //       Object.keys(data).map((item) => ({
    //         id: item,
    //         category: data[item].name,
    //       })),
    //     );
    //   }
    // });
  }, []);

  return (
    <Container>
      <Grid>
        <GridItem>
          <Link to="profile">
            <FaUserCog />
            <span>Meu perfil</span>
          </Link>
        </GridItem>
        <GridItem>
          <Link to="arts">
            <FaPaintBrush />
            <span>Artes</span>
          </Link>
        </GridItem>
        <GridItem>
          <Link to="categories">
            <FaListUl />
            <span>Categorias</span>
          </Link>
        </GridItem>
        <GridItem>
          <button type="button" onClick={signOut}>
            <FaPowerOff />
            <span>Logout</span>
          </button>
        </GridItem>
      </Grid>
    </Container>
  );
};

export default Management;
