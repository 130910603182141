import styled from 'styled-components';
import { parseToRgb } from 'polished';

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const FormContent = styled.div`
  display: flex;
  flex-direction: column;

  /* align-items: center; */
  justify-content: center;
  background: #fff;
  border: 3px solid #efefef;
  border-radius: 10px;
  width: 100vw;

  max-width: 500px;
  padding: 10px 20px;
`;

export const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;

  margin: 20px auto;
  padding: 10px 0px;

  width: 200px;

  border: 2px solid #1b5e20;
  border-radius: 10px;

  color: #006600;
  font-size: 18px;
  font-weight: bold;

  --pr: ${parseToRgb('#fff').red};
  --pg: ${parseToRgb('#fff').green};
  --pb: ${parseToRgb('#fff').blue};
  --sr: ${parseToRgb('#006600').red};
  --sg: ${parseToRgb('#006600').green};
  --sb: ${parseToRgb('#006600').blue};
  background-size: 250%;
  background-position: 0% 0%;
  background-image: linear-gradient(
    90deg,
    rgba(var(--pr), var(--pg), var(--pb), 0.5),
    rgba(var(--sr), var(--sg), var(--sb), 0.5)
  );

  transition: all 0.5s;

  &:hover {
    color: #fff;
    background-position: 100% 100%;
  }
`;
