import styled, { css, keyframes } from 'styled-components';
import { Carousel } from 'react-responsive-carousel';
import { animated } from 'react-spring';

interface PaginationProps {
  marked?: boolean;
}

interface GridProps {
  amount: number;
}

interface GridItemContainerProps {
  source: string;
}

interface CardProps {
  custom__width: number;
  custom__height: number;
}

interface CatButtonProps {
  marked: boolean;
}

export const Grid = styled.div<GridProps>`
  --gridcolor: transparent;
  padding: 0 10px;
  display: grid;

  /* TO FILL AS MANY AS POSSIBLE GIVEN A MIN ITEM WIDTH */
  /* grid-template-columns: repeat(auto-fill, minmax(13rem, 1fr)); */

  /* --grid-layout-gap: 1rem 0; */
  --grid-layout-gap: 10px;
  --grid-column-count: 10;
  --grid-item--min-width: 180px;

  --gap-count: calc(var(--grid-column-count) - 1);
  /* --total-gap-width: calc(var(--gap-count) * var(--grid-layout-gap)); */
  --total-gap-width: calc(var(--gap-count) * var(--grid-layout-gap));
  --grid-item--max-width: calc(
    (100% - var(--total-gap-width)) / var(--grid-column-count)
  );

  grid-template-columns: repeat(
    ${(props) => (props.amount >= 10 ? 'auto-fit' : 'auto-fill')},
    minmax(max(var(--grid-item--min-width), var(--grid-item--max-width)), 1fr)
  );

  /* grid-template-columns: repeat(
    auto-fit,
    minmax(
      max(var(--grid-item--min-width), var(--grid-item--max-width)),
      1fr
    )
  ); */

  grid-gap: var(--grid-layout-gap);

  align-items: center;

  > div {
    max-width: var(--grid-item--min-width);
  }

  @media screen and (max-width: 1366px) {
    --grid-item--min-width: 150px;
  }
  @media screen and (max-width: 950px) {
    --grid-item--min-width: 125px;
  }
  @media screen and (max-width: 800px) {
    --grid-item--min-width: 100px;
  }
  @media screen and (max-width: 650px) {
    --grid-item--min-width: 80px;
  }
  @media screen and (max-width: 500px) {
    --grid-item--min-width: 60px;
  }
`;

export const GridItemContainer = styled.div<GridItemContainerProps>`
  --custom-width: 180px;
  display: flex;
  margin: 0 auto;
  align-items: center;
  justify-content: center;
  width: var(--custom-width);
  max-width: var(--custom-width);
  height: var(--custom-width);
  max-height: var(--custom-width);
  border-radius: 10px;

  transition: width 0.35s ease, height 0.35s ease;

  ${(props) =>
    props.source &&
    css`
      background-color: #fff;
      background-image: url(${props.source});
    `};

  background-size: 100%;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  filter: grayscale(50%) blur(1.5px);
  transition: filter 0.35s, background-size 0.35s;

  &:hover {
    filter: grayscale(0%) blur(0px);
    background-size: 120%;
  }

  @media screen and (max-width: 1366px) {
    --custom-width: 150px;
  }
  @media screen and (max-width: 950px) {
    filter: grayscale(0%) blur(0px);
    --custom-width: 125px;
  }
  @media screen and (max-width: 800px) {
    --custom-width: 100px;
  }
  @media screen and (max-width: 650px) {
    --custom-width: 80px;
  }
  @media screen and (max-width: 500px) {
    --custom-width: 70px;
  }
`;

export const GridItem = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  background-color: transparent;
  padding: 10px 0;

  align-items: center;
  justify-content: center;

  > button {
    display: flex;
    padding: 0px 10px;
    justify-content: flex-end;
    background: transparent;
    border: 0;
    width: 100%;
    height: 100%;
  }
`;

const animateSVG = keyframes`
  0%{
    height: 15px;
    width: 15px;
  }
  100% {
    height: 20px;
    width: 20px;
  }
`;
export const SearchIcon = styled.div`
  position: absolute;
  right: 5px;
  top: 5px;
  z-index: 1;
  pointer-events: none;

  svg {
    height: 20px;
    width: 20px;
    /* animation: ${animateSVG} 1s infinite alternate; */
    color: #d4af37;
    transition: height 0.35s, width 0.35s;
  }
`;

export const ImageContainer = styled.div`
  display: flex;

  img {
    height: 600px;
    width: 600px;
  }
`;

export const AlteredCarousel = styled(Carousel)`
  width: 100vw;
  max-width: 600px;
  background: rgba(255, 255, 255, 0.1);
`;

export const CarouselContainer = styled.div`
  /* height: 650px; */
  /* padding: 25px 0px; */
`;

export const CarouselItem = styled.div`
  width: 100%;
  /* height: 600px; */
  /* padding: 10px; */

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  font-size: 14px;
  img {
    width: '100%';
    margin: auto 0;
    object-fit: contain;
  }
  a {
    /* padding: 10px 25px; */
    background: rgba(51, 46, 46, 0.25);
    text-decoration: none;
    color: #efefef;
    border: 2px solid transparent;
    border-radius: 5px;
    transition: background-color 0.5s ease, box-shadow 0.5s ease;

    span {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    div {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 10px;

      p {
      }

      svg {
        margin-left: 15px;
      }
    }

    &:hover {
      background: rgba(51, 46, 46, 1);
      box-shadow: 0px 0px 15px 3px rgba(51, 46, 46, 0.75);
    }
  }
`;

export const SelectContainer = styled.div`
  --custom-width: 270px;
  display: flex;
  margin: 30px auto;

  min-width: var(--custom-width);
  max-width: var(--custom-width);
  background: #fff;
  border-radius: 5px;
  border: 2px solid #fff;
  box-shadow: 2px 5px 10px 2px rgba(0, 0, 0, 0.35);
  transition: all 0.35s linear;

  select {
    cursor: pointer;
    font-size: 16px;
    font-family: 'Open Sans', sans-serif;
    font-weight: bold;
    background: transparent;
    color: #686868;
    padding: 5px 2px;
    width: 100%;
    height: 100%;
    border: 0px;

    ::-webkit-scrollbar {
      display: none;
    }

    &:disabled {
      cursor: not-allowed;
    }

    option {
      padding: 10px 0;
      margin: 10px 0;
      font-family: 'Roboto Slab', serif;
      font-size: 16px;
      font-weight: 500;
      color: #332e2e;
      background: #efefef;

      &:checked {
        background: #f43f5e;
        color: #fff;
      }
    }
  }
`;

const CardFace = styled.div<CardProps>`
  display: flex;

  height: 100%;
`;

export const FrontFace = styled(CardFace)`
  align-items: center;
  justify-content: center;
  div {
    display: flex;
    img {
      object-fit: cover;
    }
  }
`;

export const BackFace = styled(CardFace)`
  background: transparent;
  height: 100%;

  div {
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;

    width: 100%;
    padding: 0 30px;

    > p {
      width: 100%;
      text-align: center;
      margin: 15px 0;
      font-size: 22px;
      font-weight: bold;
    }

    section {
      display: flex;

      flex-direction: column;
      height: 100%;
      width: 100%;

      &:nth-child(2) {
        align-items: space-between;

        margin: 5px 0 0 0;
        span {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;

          position: relative;
          padding: 10px 0;
          width: 100%;

          &:before {
            content: '';
            border: 0px solid #a6a6a6;
            border-top-width: 0.125px;
            border-radius: 3px;
            position: absolute;
            width: 100%;
            top: 0;
            left: 0;
          }

          > p {
            font-size: 18px;
            color: #262626;
            font-weight: bold;
            width: 100%;
            text-align: left;

            legend {
              font-size: 16px;
              color: #565656;
              font-weight: normal;
              padding: 0 10px;
            }
          }

          & + span {
            margin: 10px 0 0 0;
          }
        }
      }

      &:nth-child(3) {
        align-items: flex-start;
        justify-content: flex-end;
        margin-bottom: 90px;
        > span {
          overflow-y: visible;
          display: grid;
          width: 100%;
          grid: auto / 1fr 1fr 1fr;
          gap: 10px;

          /* button {
            background: transparent;
            font-size: 12px;

            position: relative;
          } */
        }
      }
    }
  }
`;

export const CatButton = styled.button<CatButtonProps>`
  display: flex;
  align-items: center;
  justify-content: center;

  position: relative;
  background: transparent;
  border: 2px solid #2c5b72;
  border-radius: 5px;
  color: #1d3a49;
  font-weight: bold;
  transition: background-color 0.35s;

  &:hover {
    background-color: #abc7d4;
  }

  padding: 3px 5px;
  ${(props) =>
    props.marked &&
    css`
      background: #75a4b8;
      border-color: #1d3a49;
      color: #fff;

      &:hover {
        background-color: #233a43;
      }
    `}

  &::before {
    background: #fff;
    border: 2px solid yellow;
    content: attr(data-id);
    display: none;
  }
`;

export const PaginationContainer = styled(animated.div)`
  display: flex;
  align-items: center;
  justify-content: space-between;

  min-height: 35px;
  max-height: 35px;
  height: 35px;
  width: 300px;
  margin: 15px auto;

  background: rgba(255, 255, 255, 1);
  border-radius: 30px;
  padding: 5px 0px;
  box-shadow: 2px 5px 10px 2px rgba(0, 0, 0, 0.35);
  overflow: hidden;
  button {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 30px;
    width: 30px;
    border: 0;

    font-size: 16px;
    font-weight: bold;
    border-radius: 50%;
    /* background: #7159c1; */

    &:nth-child(1),
    &:nth-last-child(1) {
      background: transparent;

      &:hover {
        color: #4d4d4d;
      }
    }

    &:disabled {
      cursor: none;
      pointer-events: none;
    }
  }
`;

export const PaginationItem = styled.button<PaginationProps>`
  background: transparent;
  transition: color 0.35s;

  &:hover {
    color: #b3b3b3;
  }
  ${(props) =>
    props.marked &&
    css`
      background: #b3b3b3;
      box-shadow: 0px 0px 10px 2px rgba(244, 63, 94, 0.35);
      box-shadow: 0px 0px 10px 2px rgba(102, 102, 102, 0.35);

      &:hover {
        color: #fff;
      }
    `}
`;
