import React from 'react';
import { Navigate, Route, Routes as ReactRoutes } from 'react-router-dom';

import Home from 'pages/Home';
import Gallery from 'pages/Gallery';
import NotFound from 'pages/NotFound';
import Auth from 'pages/ADMIN/Auth';
import Management from 'pages/ADMIN/Management/main';
import CategoriesManagement from 'pages/ADMIN/Management/categories/main';
import CategoriesManagementUpdate from 'pages/ADMIN/Management/categories/update';
import ArtsManagement from 'pages/ADMIN/Management/arts/main';

import ArtsManagementInsert from 'pages/ADMIN/Management/arts/insert';
import ArtsManagementUpdate from 'pages/ADMIN/Management/arts/update';
import Profile from 'pages/ADMIN/Profile';

import PrivateRoute from './PrivateRoute';

const Routes: React.FC = () => {
  return (
    <ReactRoutes>
      <Route path="*" element={<NotFound />} />
      <Route path="/" element={<Navigate replace to="/home" />} />
      <Route path="/home" element={<Home />} />
      <Route path="/gallery/*" element={<Gallery />} />
      <Route path="/admin/login" element={<Auth />} />
      <Route
        path="/admin/profile"
        element={<PrivateRoute outlet={<Profile />} />}
      />
      <Route path="/admin" element={<PrivateRoute outlet={<Management />} />} />
      <Route
        path="/admin/categories"
        element={<PrivateRoute outlet={<CategoriesManagement />} />}
      />
      <Route
        path="/admin/categories/update"
        element={<PrivateRoute outlet={<CategoriesManagementUpdate />} />}
      />
      <Route
        path="/admin/arts"
        element={<PrivateRoute outlet={<ArtsManagement />} />}
      />

      <Route
        path="/admin/arts/insert"
        element={<PrivateRoute outlet={<ArtsManagementInsert />} />}
      />
      <Route
        path="/admin/arts/update"
        element={<PrivateRoute outlet={<ArtsManagementUpdate />} />}
      />
    </ReactRoutes>
  );
};

export default Routes;
