import React, {
  useState,
  useCallback,
  HTMLAttributes,
  MouseEvent,
} from 'react';
import { FaTimes } from 'react-icons/fa';

import { useSpring } from 'react-spring';

import {
  ModalContainer,
  ModalContent,
  CloseButton,
  ModalCloser,
} from './styles';

interface ModalProps extends HTMLAttributes<HTMLDivElement> {
  open?: boolean;
  // callbackFunction?: () => void;
}

const Modal: React.FC<ModalProps> = ({
  children = '',
  open = false,

  // callbackFunction = null,
}) => {
  const [active, toggleActive] = useState(open);

  const toggleState = useCallback((e: MouseEvent) => {
    e.stopPropagation();
    toggleActive((state) => !state);
  }, []);

  const openStyle = useSpring({
    opacity: active === true ? 1 : 0,
    filter: active === true ? 'blur(0px)' : 'blur(5px)',
  });

  return (
    <ModalContainer
      style={openStyle}
      pointerEvents={active === true ? 'all' : 'none'}
    >
      <ModalContent>
        <CloseButton>
          <button type="button" id="modalButton" onClick={toggleState}>
            <FaTimes />
          </button>
        </CloseButton>
        {children}
      </ModalContent>
      <ModalCloser onClick={toggleState}>{/*  */}</ModalCloser>
    </ModalContainer>
  );
};

export default Modal;
export const toggleModal = () => {
  const btn = document.getElementById('modalButton');

  if (btn) {
    btn.click();
  }
};
