import React, { useRef, HTMLAttributes } from 'react';
import './burger.css';

interface BurgerProps extends HTMLAttributes<HTMLDivElement> {
  callbackfunction: () => void;
}

const Burger: React.FC<BurgerProps> = ({ callbackfunction }) => {
  const inputRef = useRef<HTMLInputElement>(null);

  return (
    <div className="burger-container">
      <input
        ref={inputRef}
        type="checkbox"
        name="burger-checkbox"
        id="burger-checkbox"
        onClick={callbackfunction}
      />
      <div className="burger">
        <div />
      </div>
    </div>
  );
};

export default Burger;
