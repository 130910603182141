import styled, { keyframes } from 'styled-components';

interface NotFoundContainerProps {
  pic: string;
}

const moveBG = keyframes`
  from{
    background-position: 0px 0px;
  } to {
    background-position: 1920px 1080px;
  }
`;

export const NotFoundContainer = styled.div<NotFoundContainerProps>`
  height: 100vh;
  width: 100vw;
  /* background: #363636; */
  overflow: hidden;
  background-image: url(${(props) => props.pic});
  animation: ${moveBG} 25s linear infinite;
`;

export const NotFoundContent = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Message = styled.div`
  --maincolor: #f99fae;
  --secondary: #f43f5e;
  --white: #fff;

  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  background: var(--white);
  /* height: 400px; */
  width: 400px;

  border: 5px solid var(--maincolor);
  border-radius: 25px;
  box-shadow: 0px 0px 15px 15px var(--maincolor),
    inset 0px 0px 2px 2px var(--maincolor);
  padding: 20px 30px;

  p {
    color: #464646;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-family: 'Dancing Script', cursive;
    font-size: 50px;
    svg {
      margin-left: 35px;
      height: 50px;
      width: 50px;
    }

    & + p {
      text-align: center;
      font-family: 'Roboto Slab', serif;
      margin-top: 20px;
      font-size: 20px;
    }
  }

  button {
    margin-top: 20px;
    color: var(--white);
    padding: 5px 25px;

    background: transparent;
    border: 2px solid var(--secondary);
    border-radius: 5px;
    transition: all 0.35s linear;

    > span {
      > svg {
        color: var(--secondary);
        height: 25px;
        width: 25px;
        transform: rotate(180deg);

        transition: color 0.35s linear;
      }
    }

    &:hover {
      background: var(--secondary);
      border-color: var(--secondary);

      > span {
        > svg {
          color: var(--white);
        }
      }
    }
  }
`;
