import React from 'react';

import { useLoading } from 'hooks/loading';

import { useSpring } from 'react-spring';
import { FullLoadingContainer } from './styles';

const FullLoading: React.FC = () => {
  const { isLoading } = useLoading();

  // useEffect(() => {
  //   setTimeout(() => {
  //     toggleLoading();
  //   }, 5000);
  //   setTimeout(() => {
  //     toggleLoading();
  //   }, 10000);
  // }, []);

  const styledLoading = useSpring({
    opacity: isLoading ? 1 : 0,
    // zIndex: isLoading ? '10004' : '-1',
  });

  return (
    <FullLoadingContainer
      style={{ ...styledLoading, pointerEvents: isLoading ? 'all' : 'none' }}
    >
      <div>
        <span />
        <span />
        <span />
      </div>
    </FullLoadingContainer>
  );
};

export default FullLoading;
