import styled from 'styled-components';

export const Wrapper = styled.div`
  background: rgba(255, 255, 255, 0.75);
  border-color: #b6b6b6;
  border-width: 2px 0px;
  border-style: solid;
  overflow: hidden;
  padding: 10px;
`;

export const SaveButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 30px auto;
  margin-right: 25px;
  width: 70px;
  height: 70px;
  background: #aed581;
  border: 3px solid #558b2f;
  color: #1b5e20;
  border-radius: 10px;

  svg {
    height: 30px;
    width: 30px;
  }
`;
