import React, { useCallback, useEffect, useState, ChangeEvent } from 'react';

import Container from 'components/container';
import Input from 'components/inputWithoutForm';

import { Link } from 'react-router-dom';

import { useCategory } from 'hooks/category';
import { useFirebase } from 'hooks/firebase';

import { ref as dbRef, onValue, set } from 'firebase/database';
import { ref, deleteObject } from 'firebase/storage';

import { removeAcento } from 'utils/specialchars';

import {
  FaExclamationTriangle,
  FaPlusCircle,
  FaTimesCircle,
} from 'react-icons/fa';
import { api } from 'services/api';
import {
  GridWrapper,
  Grid,
  GridItem,
  ButtonDelete,
  NewContainer,
} from './styles';

interface LanguageProps {
  material: string;
  type: string;
  description: string;
  name: string;
}

interface ArtCategoryProps {
  value: string;
  label: string;
}

export interface ArtsProps {
  id: string;
  ref: string;
  downloadURL: string;
  category: ArtCategoryProps[];
  size: string;
  visible: boolean;
  ptbr: LanguageProps;
  it: LanguageProps;
  missingInfo: boolean;
}

const ArtsManagement: React.FC = () => {
  const [language] = useState('ptbr');

  const { allcategories } = useCategory();
  const { database, storage, db_env } = useFirebase();
  // const { toggleLoading } = useLoading();
  // const { toggleLoading } = useLoading();

  // const [imgRefs, setImgRefs] = useState<{ id: string; url: string }[]>([]);
  const [rawArts, setRawArts] = useState<ArtsProps[]>([]);
  const [arts, setArts] = useState<ArtsProps[]>([]);

  useEffect(() => {
    // toggleLoading(false);
    // const imagesListRef = ref(storage, 'arts/');
    // list(imagesListRef).then((response) => {
    //   response.items.forEach((item) => {
    //     getMetadata(item).then(async (url) => {
    //       const source = await getDownloadURL(url.ref).then((src) => src);
    //       setImgRefs((prev) => [...prev, { id: url.name, url: source }]);
    //     });
    //   });
    // });
  }, []);

  useEffect(() => {
    if (allcategories.length > 0) {
      const retrieveArts = dbRef(database, `${db_env}/arts`);

      onValue(retrieveArts, async (snapshot) => {
        const data = snapshot.val();
        let filtered = [];
        if (data) {
          filtered = Object.keys(data).map((item) => {
            const miss =
              data[item].category[0] !== '1' &&
              !!data[item].downloadURL &&
              data[item].downloadURL.length > 0 &&
              !!data[item].ref &&
              data[item].ref.length > 0 &&
              data[item].size.length > 0 &&
              data[item].ptbr.material.length > 0 &&
              data[item].ptbr.type.length > 0 &&
              data[item].ptbr.description.length > 0 &&
              data[item].ptbr.name.length > 0 &&
              data[item].it.material.length > 0 &&
              data[item].it.type.length > 0 &&
              data[item].it.description.length > 0 &&
              data[item].it.name.length > 0;
            // console.table({
            //   category: data[item].category[0],
            //   downloadURL: data[item].downloadURL,
            //   ref: data[item].ref,
            //   size: data[item].size,
            //   ptbrmaterial: data[item].ptbr.material,
            //   ptbrtype: data[item].ptbr.type,
            //   ptbrdescription: data[item].ptbr.description,
            //   ptbrname: data[item].ptbr.name,
            //   itmaterial: data[item].it.material,
            //   ittype: data[item].it.type,
            //   itdescription: data[item].it.description,
            //   itname: data[item].it.name,
            // });

            return {
              ...data[item],
              missingInfo: !miss,
              category: data[item].category.map((itemcat) => {
                const index = allcategories
                  .map((cat) => {
                    return cat.ref;
                  })
                  .indexOf(itemcat);

                return {
                  value: itemcat,
                  label: allcategories[index][language],
                };
              }),
            };
          });
        }

        filtered = filtered.sort((a: ArtsProps, b: ArtsProps) =>
          removeAcento(a.ptbr.name).toLowerCase() >
          removeAcento(b.ptbr.name).toLowerCase()
            ? 1
            : removeAcento(b.ptbr.name).toLowerCase() >
              removeAcento(a.ptbr.name).toLowerCase()
            ? -1
            : 0,
        );
        setRawArts(filtered);
        setArts(filtered);
        // toggleLoading();
      });
    }
  }, [allcategories]);

  const handleDelete = async (id: string, objectRef: string) => {
    const confirmed = window.confirm(
      'Você está prestes a deletar PERMANENTEMENTE esta arte.\n\nDeseja prosseguir com esta ação?',
    );
    if (confirmed) {
      await set(dbRef(database, `${db_env}/arts/${id}`), null);
      const objectToDelete = ref(storage, `${db_env}/arts/${objectRef}`);
      await deleteObject(objectToDelete);

      const data = new FormData();
      data.append('ref', objectRef);

      await api.post('/delete.php', data, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
    }
  };

  const handleSearch = useCallback(
    (e: string) => {
      setArts(
        rawArts.filter((item: ArtsProps) =>
          removeAcento(item.ptbr.name)
            .toLowerCase()
            .includes(removeAcento(e).toLowerCase()),
        ),
      );
    },
    [rawArts],
  );

  return (
    <Container>
      <NewContainer>
        <Link to="insert">
          <FaPlusCircle />
        </Link>
      </NewContainer>
      <div style={{ margin: '25px 0 0 0' }}>
        <Input
          label="Filtre por nome:"
          name="filtro"
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            handleSearch(e.target.value);
          }}
        />
      </div>
      <GridWrapper>
        <Grid amount={arts.length}>
          {arts.map((item) => (
            <GridItem key={item.id} visible={item.visible}>
              <div>
                <Link to="update" state={{ ...item }}>
                  {item.downloadURL ? (
                    <img src={item.downloadURL} alt={item.ptbr.name} />
                  ) : (
                    <p>Sem foto</p>
                  )}
                </Link>
              </div>
              <div>
                <div>
                  <strong>{item.ptbr.name}</strong>
                </div>
                <ButtonDelete>
                  {/* <Link to="update" state={{ ...item }}>
                    <FaPencilAlt />
                  </Link> */}
                  <button
                    type="button"
                    onClick={() => handleDelete(item.id, item.ref)}
                  >
                    <FaTimesCircle />
                  </button>
                </ButtonDelete>
              </div>
              {item.missingInfo && (
                <div>
                  <FaExclamationTriangle />
                </div>
              )}

              {/*

              <div>
                {item.downloadURL ? (
                  <img
                    src={item.downloadURL}
                    alt="none"
                    style={{ height: '50px', objectFit: 'contain' }}
                  />
                ) : (
                  <div
                    style={{ display: 'block', height: '50px', width: '50px' }}
                  />
                )}
              </div>
              <div>
                <p>
                  ref:&nbsp;<strong>{item.id}</strong>
                </p>
              </div>
              <div>{item[language].name}</div>

              <div>
                <p>
                  Categorias:&nbsp;
                  <strong>
                    {item.category.map(
                      (cat, index) =>
                        `${cat.label}${
                          index < item.category.length - 1 ? ', ' : ''
                        }`,
                    )}
                  </strong>
                </p>
              </div>
              <div>{item[language].material}</div>
              <div>{item[language].type}</div>
              <div>
                <p>Visível:</p>
                <strong>{JSON.stringify(item.visible)}</strong>
              </div> */}
            </GridItem>
          ))}
        </Grid>
      </GridWrapper>
    </Container>
  );
};

export default ArtsManagement;
