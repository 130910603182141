import React, {
  InputHTMLAttributes,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

import { InputContainer, Label } from './styles';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  label?: string;
  isHidden?: boolean;
}

const Input: React.FC<InputProps> = ({
  name,
  label = '',
  isHidden = false,
  defaultValue = '',
  ...rest
}) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const [isFocused, setIsFocused] = useState(false);
  const [isFilled, setIsFilled] = useState(() => {
    return !!defaultValue || false;
  });

  useEffect(() => {
    // registerField({
    //   name: fieldName,
    //   ref: inputRef.current,
    //   path: 'value',
    // });
  }, []);
  // TODO: refactor with container

  const handleFocus = useCallback(() => {
    setIsFocused(true);
  }, []);

  const handleBlur = useCallback(() => {
    setIsFocused(false);

    setIsFilled(!!inputRef.current?.value);
  }, []);

  return (
    <InputContainer hidden={isHidden}>
      <Label
        htmlFor={name}
        data-display-name={label}
        focused={isFocused}
        filled={isFilled}
      >
        <input
          ref={inputRef}
          name={name}
          {...rest}
          defaultValue={defaultValue}
          style={{ display: isHidden ? 'none' : 'initial' }}
          onFocus={handleFocus}
          onBlur={handleBlur}
        />
      </Label>
    </InputContainer>
  );
};

export default Input;
