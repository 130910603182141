import { createGlobalStyle } from 'styled-components';

// const URL = `${process.env.REACT_APP_ASSETS}/sketch1659965801047.png`;
export default createGlobalStyle`

  * {
    margin:0;
    padding: 0;
    box-sizing: border-box;
    outline: 0;
    -webkit-tap-highlight-color: transparent;
    -webkit-user-drag: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  html {
    font-size: 1rem;
    font-family: 'Open Sans', sans-serif;
  }

  body {
    background: #fff;
    /* background: #fffdd0; */
    /* background-image: url(${process.env.REACT_APP_BACKGROUND}), linear-gradient(to bottom, #fecdd3, #f43f5e); */
    background-position: 0% 0%;
    background-repeat: repeat;
    overflow-x: hidden;

    :-webkit-scrollbar {
      width: 5px;
    }
    ::-webkit-scrollbar-track {
      background: #332e2e;
    }
    ::-webkit-scrollbar-thumb {
      background: rgba(255, 255, 255, 0.65);
      border-radius: 10px;

      &:hover {
        background: rgba(255, 255, 255, 1);
      }
    }
  }

  button {
    cursor: pointer;
  }
`;

/*
#1d3a49
#2c5b72
#75a4b8
#a3b2c3
#ddd5df
#c49bad
#a5879b
#b69493
#8f6563
#705560

#000000
#332e2e
#5e5858
#8c8484
#ada3a3
#d1c5c5
#efefef
#ffffff
*/
