import styled, { css } from 'styled-components';
import { lighten } from 'polished';

interface GridProps {
  amount: number;
}

interface GridItemProps {
  visible: boolean;
}

export const GridWrapper = styled.div`
  margin: 35px 0;
  /* justify-items: center; */
`;

export const Grid = styled.div<GridProps>`
  padding: 10px;
  display: grid;

  --grid-layout-gap: 35px;
  --grid-column-count: 6;
  --grid-item--min-width: 250px;

  --gap-count: calc(var(--grid-column-count) - 1);

  --total-gap-width: calc(var(--gap-count) * var(--grid-layout-gap));
  --grid-item--max-width: calc(
    (100% - var(--total-gap-width)) / var(--grid-column-count)
  );

  grid-template-columns: repeat(
    ${(props) => (props.amount >= 6 ? 'auto-fit' : 'auto-fill')},
    minmax(max(var(--grid-item--min-width), var(--grid-item--max-width)), 1fr)
  );

  grid-gap: var(--grid-layout-gap);

  align-items: center;

  > div {
    width: var(--grid-item--min-width);
  }

  /* width: 100vw;

  grid: auto / 1fr 1fr 1fr 1fr 1fr;
  justify-items: center;
  gap: 30px 15px; */

  /* @media screen and (max-width: ); */
`;

export const GridItem = styled.div<GridItemProps>`
  margin: 0 auto;
  position: relative;
  display: flex;
  /* width: 250px; */

  > div {
    strong {
      font-size: 14px;
    }
    &:nth-child(1) {
      position: absolute;
      top: 50%;
      left: 0;
      transform: translate(-10%, -50%);

      display: flex;
      align-items: center;
      justify-content: center;

      width: 80px;
      height: 80px;

      background: #fff;
      border-radius: 50%;
      border: 4px solid #801a00;

      ${(props) =>
        props.visible &&
        css`
          border-color: #206020;
        `}

      overflow: hidden;
      box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.5);

      transition: box-shadow 0.35s;
      a {
        display: flex;
        align-items: center;
        justify-content: center;

        height: 100%;
        width: 100%;
        text-decoration: none;

        background-image: radial-gradient(#efefef, #262626);
        color: #332e2e;

        font-weight: bold;
        font-family: 'Roboto Slab';
      }
      img {
        height: 100%;
        object-fit: contain;
        transition: transform 0.35s ease;
      }
    }
    &:nth-child(2) {
      margin: 0 0 0 45px;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 55px;
      width: 100%;

      background: #ffebe6;
      border: 2px solid #e62e00;
      border-radius: 5px 17px 17px 5px;

      /* padding-left: 50px; */

      box-shadow: 2px 2px 5px 2px rgba(0, 0, 0, 0.5);

      transition: box-shadow 0.35s;

      ${(props) =>
        props.visible &&
        css`
          border-color: #339933;
          background-color: #ecf9ec;
        `}
    }

    > div {
      &:nth-child(2) {
        display: flex;
        /* background: red; */
        height: 100%;

        align-items: flex-end;
        justify-content: center;

        svg {
          height: 25px;
          width: 25px;
        }
        a {
          color: #75a4b8;
          margin: 0 10px 0 0;
        }

        button {
          margin-bottom: 3px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 50%;
          color: #c53030;
          border: 0;
          background: #fff;
        }
      }
    }

    &:nth-child(3) {
      position: absolute;
      left: -15px;
      bottom: -15px;

      display: flex;
      align-items: center;
      justify-content: center;
      border: 2px solid #000;
      background: #000;
      border-radius: 50%;
      height: 35px;
      width: 35px;

      svg {
        height: 25px;
        width: 25px;
        color: #fce205;
      }
    }
  }

  &:hover {
    > div {
      &:nth-child(1) {
        box-shadow: 2px 2px 10px 3px rgba(0, 0, 0, 0.5);
        img {
          transform: scale(1.15);
        }
      }
      &:nth-child(2) {
        box-shadow: 2px 2px 10px 3px rgba(0, 0, 0, 0.5);
      }
    }
  }

  /* background: #fff;
  border: 3px solid #efefef;
  border-radius: 5px;
  box-shadow: 1px 2.5px 5px 1.5px rgba(0, 0, 0, 0.5); */
`;

export const ButtonDelete = styled.div`
  position: absolute;
  right: -10px;
  bottom: -10px;
`;

export const NewContainer = styled.div`
  position: fixed;
  top: 75px;
  right: 15px;
  width: 45px;
  height: 45px;
  z-index: 2;

  a {
    background: #aed581;
    border: 3px solid #558b2f;
    color: #1b5e20;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 45px;
    height: 45px;

    border-radius: 50%;
    box-shadow: 0px 0px 10px 3px rgba(205, 232, 187, 0.75);
    transition: all 0.35s;
    svg {
      height: 30px;
      width: 30px;
      transition: all 0.35s;
    }

    &:hover {
      background-color: ${lighten(0.05, '#aed581')};
      border-color: ${lighten(0.1, '#558b2f')};
      box-shadow: 0 0 10px 5px rgba(205, 232, 187, 0.75);
      svg {
        color: ${lighten(0.05, '#1b5e20')};
      }
    }
  }
`;
