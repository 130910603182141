import styled from 'styled-components';
import { animated } from 'react-spring';

interface ModalContainerProps {
  pointerEvents: string;
}

export const ModalContainer = styled(animated.div)<ModalContainerProps>`
  display: flex;
  align-items: center;
  justify-content: center;

  position: absolute;
  top: 0;
  left: 0;

  pointer-events: ${(props) => props.pointerEvents};
  width: 100%;
  height: 100%;

  background-color: rgba(0, 0, 0, 0.85);
  z-index: 10000;
`;

export const ModalCloser = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10001;
  width: 100%;
  height: 100%;
  background-color: transparent;
`;

export const ModalContent = styled.div`
  z-index: 10002;
  display: flex;
  align-items: center;
  justify-content: center;
  /* height: 90%; */
  /* min-width: 360px; */

  background-color: #fff;

  position: relative;
  border-radius: 10px;
  overflow: hidden;
`;

export const CloseButton = styled.div`
  z-index: 10003;
  position: absolute;
  top: 0px;
  right: 0px;
  background-color: transparent;
  border-radius: 10px;

  /* overflow: hidden; */

  button {
    /* margin: 20px 20px 0 0; */
    margin: 0;
    display: flex;
    /* align-items: center; */
    height: 35px;
    width: 35px;
    border: none;
    background-color: transparent;

    svg {
      color: #c53030;
      width: 25px;
      height: 25px;
      margin: 2px 0 0 4px;
    }
  }
`;
