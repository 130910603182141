import React, { createContext, useContext } from 'react';
import { AppProps } from 'interfaces';

interface GlobalStorageProps {
  [key: string]: string | number | null | object | undefined | boolean;
}

interface LocalStorageContextData {
  setLocalStorage: (item: string, values: GlobalStorageProps) => void;
  getLocalStorage: (item: string) => object;
  deleteLocalStorageItemKey: (item: string, values: string[]) => void;
}

const LocalStorageContext = createContext<LocalStorageContextData>(
  {} as LocalStorageContextData,
);

const LocalStorageProvider: React.FC<AppProps> = ({ children }) => {
  // const setLocalStorage = useCallback(
  //   (item: string, values: GlobalStorageProps) => {},
  //   [],
  // );

  // const getLocalStorage = useCallback(() => {}, []);

  // const deleteLocalStorageItemKey = useCallback(() => {}, []);
  const setLocalStorage = (item: string, values: GlobalStorageProps) => {
    const localstorageitem = localStorage.getItem(item);

    if (localstorageitem) {
      const parsed = JSON.parse(localstorageitem);

      const updatedItem = {
        ...parsed,
        ...values,
      };

      localStorage.setItem(item, JSON.stringify(updatedItem));
    } else {
      localStorage.setItem(item, JSON.stringify(values));
    }
  };

  const getLocalStorage = (item: string): object => {
    const localStorageItem = localStorage.getItem(item);

    if (localStorageItem) {
      return JSON.parse(localStorageItem);
    }

    return {};
  };

  const deleteLocalStorageItemKey = (item: string, values: string[]): void => {
    const localStorageItem = localStorage.getItem(item);

    if (localStorageItem) {
      const stored = JSON.parse(localStorageItem);
      values.map((ocurr) => {
        delete stored[ocurr];
        return null;
      });
      localStorage.setItem(item, JSON.stringify(stored));
    }
  };

  return (
    <LocalStorageContext.Provider
      value={{ setLocalStorage, getLocalStorage, deleteLocalStorageItemKey }}
    >
      {children}
    </LocalStorageContext.Provider>
  );
};

const useLocalStorage = (): LocalStorageContextData => {
  const context = useContext(LocalStorageContext);
  if (!context) {
    throw new Error(
      'useLocalStorage must be used within a LocalStorageProvider',
    );
  }
  return context;
};
export { LocalStorageProvider, useLocalStorage };
