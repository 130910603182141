import styled from 'styled-components';
import { parseToRgb } from 'polished';

export const FormWrapper = styled.div`
  width: 100vw;
  max-width: 600px;
  display: flex;
  /* height: 600px; */
  min-height: 200px;
  overflow-y: scroll;
  padding: 25px;
  ::-webkit-scrollbar {
    width: 2px;
  }
  form {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
  }
  div {
    width: 300px;
    display: flex;
    justify-content: space-around;
    /* flex-direction: column; */

    margin: 0 auto;
    /* background: #c53030; */

    button {
      --size: 20px;

      display: flex;
      align-items: center;
      justify-content: center;
      padding: calc((var(--size) / 2) - 2.5px) calc(var(--size) - 5px);

      --pr: ${parseToRgb('#64DD17').red};
      --pg: ${parseToRgb('#64DD17').green};
      --pb: ${parseToRgb('#64DD17').blue};
      --sr: ${parseToRgb('#004D40').red};
      --sg: ${parseToRgb('#004D40').green};
      --sb: ${parseToRgb('#004D40').blue};
      background-size: 200%;
      background-position: 0% 0%;
      background-image: linear-gradient(
        45deg,
        rgba(var(--sr), var(--sg), var(--sb), 1),
        rgba(var(--pr), var(--pg), var(--pb), 1)
      );
      /* width: 100px; */
      border: 2px solid #004d40;
      border-radius: var(--size);

      box-shadow: 1px 2.5px 5px 1.5px rgba(0, 0, 0, 0.5);
      /* box-shadow: 2px 5px 10px 3px rgba(0, 0, 0, 0.5); */

      svg {
        height: var(--size);
        width: var(--size);
        color: #c5e1a5;
        transition: all 0.5s;
        & + svg {
          margin-left: 5px;
        }
      }

      transition: all 0.5s;
      &:hover {
        background-position: 100% 0%;
        border-color: #76ff03;

        svg {
          color: white;
        }
      }
    }

    & + div {
      margin: 20px 0 0 0;
    }
  }
`;
