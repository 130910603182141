import { parseToRgb } from 'polished';
import styled, { css } from 'styled-components';

interface LabelProps {
  marked: boolean;
  disabled?: boolean;
}

export const Wrapper = styled.div`
  background: rgba(255, 255, 255, 0.75);
  border-color: #b6b6b6;
  border-width: 2px 0px;
  border-style: solid;
  overflow: hidden;
  padding: 10px;
`;
export const LanguageContainer = styled.div`
  /* display: none; */
  display: flex;
  justify-content: center;
  > div {
    width: 48%;
    & + div {
      margin: 0 0 0 30px;
    }
  }

  @media screen and (max-width: 1100px) {
    flex-direction: column;
    align-items: center;
    justify-content: unset;

    > div {
      width: 98%;

      & + div {
        margin: 10px 0 0 0;
      }
    }
  }
`;
export const LanguageContent = styled.div``;

export const CategoryContainer = styled.div`
  margin: 20px 0 0 0;
  display: flex;
  flex-direction: column;
  strong {
  }
  div {
    margin: 15px auto 35px;
    width: 90vw;
    display: grid;
    grid: auto / 1fr 1fr 1fr 1fr 1fr;
    /* justify-items: center; */
    gap: 15px;

    @media screen and (max-width: 880px) {
      grid: auto / 1fr 1fr 1fr 1fr;
    }
    @media screen and (max-width: 650px) {
      grid: auto / 1fr 1fr 1fr;
    }
    @media screen and (max-width: 500px) {
      grid: auto / 1fr 1fr;
    }
  }
`;

export const CategoryLabel = styled.label<LabelProps>`
  cursor: pointer;
  width: 150px;
  height: 50px;

  border-radius: 5px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  transition: all 0.5s;
  /* border: 3px solid #00b4cc; */

  &:before {
    font-size: 18px;
    font-weight: bold;
    position: absolute;
    content: attr(data-display);
  }

  --pr: ${parseToRgb('#fff').red};
  --pg: ${parseToRgb('#fff').green};
  --pb: ${parseToRgb('#fff').blue};
  --sr: ${parseToRgb('#000').red};
  --sg: ${parseToRgb('#000').green};
  --sb: ${parseToRgb('#000').blue};
  background-size: 200%;
  background-position: 0% 0%;
  background-image: linear-gradient(
    -45deg,
    rgba(var(--pr), var(--pg), var(--pb), 0.5),
    rgba(var(--sr), var(--sg), var(--sb), 0.5)
  );

  input {
    cursor: pointer;
    opacity: 0;
    /* display: none; */
    width: 100%;
    height: 100%;
  }

  ${(props) =>
    props.marked &&
    css`
      --pr: ${parseToRgb('#00b4cc').red};
      --pg: ${parseToRgb('#00b4cc').green};
      --pb: ${parseToRgb('#00b4cc').blue};
      --sr: ${parseToRgb('#ff0095').red};
      --sg: ${parseToRgb('#ff0095').green};
      --sb: ${parseToRgb('#ff0095').blue};
    `}

  &:hover {
    background-position: 100% 0%;
  }

  ${(props) =>
    props.disabled &&
    css`
      pointer-events: none;
    `}
`;

export const ButtonCreateCategory = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  border: 0;
  background: transparent;

  svg {
    color: #43a047;
    width: 45px;
    height: 45px;
    transition: color 0.35s;
  }

  &:hover {
    svg {
      color: #1b5e20;
    }
  }
`;

export const DimensionContainer = styled.div``;

export const VisibleContainer = styled.div<LabelProps>`
  margin: 20px 0 0 0;
  height: 150px;
  display: flex;

  label {
    position: relative;
    /* margin: 0 auto; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &:before {
      font-size: 16px;
      font-weight: bold;
      position: absolute;
      top: 0;
      left: 0;
      content: attr(data-display);
    }

    svg {
      height: 50px;
      width: 50px;
      color: #d50000;
      ${(props) =>
        props.marked &&
        css`
          color: #1b5e20;
        `}
    }

    input {
      position: absolute;
      opacity: 0;
      width: 100%;
      height: 100%;
    }
  }
`;

export const PhotoContainer = styled.div`
  margin: 20px 0 0 0;
  /* justify-content: center; */
  margin: 20px 15px 0;
  display: flex;

  > div {
    width: 200px;
    &:nth-child(1) {
      margin: 0 50px 0 0;
    }
    &:nth-child(2) {
      margin: 0 0 0 50px;
    }

    div {
      p {
        font-size: 16px;
        font-weight: bold;
        color: #332e2e;
        margin: 0 0 10px 0;
      }
      img {
        border-radius: 5px;
        width: 100%;
        object-fit: contain;
        box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.5);
      }
    }
  }

  label {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto 0;
    height: 100px;
    width: 100px;
    border-radius: 50%;
    box-shadow: 0px 0px 5px 1.5px rgba(0, 0, 0, 0.5);

    --primary: rgba(255, 255, 255, 0.5);
    --secondary: rgba(0, 0, 0, 0.5);
    background-size: 200%;
    background-position: 0% 0%;
    background-image: linear-gradient(
      45deg,
      var(--secondary),
      var(--primary),
      var(--secondary)
    );

    transition: all 0.5s;

    input {
      display: none;
    }

    svg {
      height: 60px;
      width: 60px;
    }

    &:hover {
      background-position: 100%;
      box-shadow: 1px 2.5px 10px 3px rgba(0, 0, 0, 0.5);
    }
  }
`;

export const SaveButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 30px auto;
  margin-right: 25px;
  width: 70px;
  height: 70px;
  background: #aed581;
  border: 3px solid #558b2f;
  color: #1b5e20;
  border-radius: 10px;

  svg {
    height: 30px;
    width: 30px;
  }
`;
