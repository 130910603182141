import styled, { css } from 'styled-components';

interface TextareaProps {
  hidden: boolean;
}

interface LabelProps {
  focused: boolean;
  filled: boolean;
}

export const TextareaContainer = styled.div<TextareaProps>`
  display: flex;
  height: 230px;
  padding: 30px 0 0 0;

  /* background: #fff; */
  ${(props) =>
    props.hidden &&
    css`
      display: none;
    `}
`;

export const Label = styled.label<LabelProps>`
  position: relative;
  display: flex;
  padding: 5px;

  width: 100%;
  height: 200px;

  &:before {
    font-size: 20px;
    content: attr(data-display-name);
    pointer-events: none;
    position: absolute;
    top: 35px;
    left: 10px;
    transform: translateY(-125%);

    transition: top 0.35s, left 0.35s, transform 0.35s, color 0.35s;
    will-change: top, left, color;
  }

  textarea {
    border: solid 2px transparent;

    width: 100%;

    resize: none;
    font-size: 18px;
    padding: 7px 0 0 5px;
    /* border: 0; */
    background: transparent;
    border-radius: 0;

    transition: border-color 0.35s;
    will-change: border-color, border-radius;

    ::-webkit-scrollbar {
      width: 2px;
    }
    ::-webkit-scrollbar-track {
      background: #f06292;
    }
    ::-webkit-scrollbar-thumb {
      background: #fff;
      border-radius: 10px;
    }
  }

  ${(props) =>
    props.focused &&
    css`
      &:before {
        color: #f06292;
        top: 10px;
        left: 5px;
      }

      textarea {
        border-right-color: #a6a6a6;
        border-bottom-color: #a6a6a6;
        border-left-color: #a6a6a6;
        border-radius: 0px 0px 10px 10px;
      }
    `}

  ${(props) =>
    props.filled &&
    css`
      &:before {
        top: 10px;
        left: 5px;
      }

      textarea {
        border-right-color: #f06292;
        border-bottom-color: #f06292;
        border-left-color: #f06292;
        border-radius: 0px 0px 10px 10px;
      }
    `}
`;
