import React, {
  useEffect,
  useRef,
  TextareaHTMLAttributes,
  useState,
  useCallback,
} from 'react';

import { useField } from '@unform/core';

import { TextareaContainer, Label } from './styles';

interface TextareaProps extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  name: string;
  label?: string;
  isHidden?: boolean;
}

const TextArea: React.FC<TextareaProps> = ({
  name,
  label = '',
  isHidden = false,
  ...rest
}) => {
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const { fieldName, defaultValue, error, registerField } = useField(name);

  const [isFocused, setIsFocused] = useState(false);
  const [isFilled, setIsFilled] = useState(() => {
    return !!defaultValue || false;
  });

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: textareaRef.current,
      path: 'value',
    });
  }, []);

  const handleFocus = useCallback(() => {
    setIsFocused(true);
  }, []);

  const handleBlur = useCallback(() => {
    setIsFocused(false);

    setIsFilled(!!textareaRef.current?.value);
  }, []);
  // TODO: refactor with container
  return (
    <TextareaContainer hidden={isHidden}>
      <Label
        htmlFor={name}
        data-display-name={label}
        focused={isFocused}
        filled={isFilled}
      >
        <textarea
          ref={textareaRef}
          name={name}
          defaultValue={defaultValue}
          style={{ display: isHidden ? 'none' : 'initial' }}
          // cols={45}
          rows={10}
          {...rest}
          onFocus={handleFocus}
          onBlur={handleBlur}
        />
      </Label>
      {error}
    </TextareaContainer>
  );
};

export default TextArea;
