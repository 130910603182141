import styled from 'styled-components';

export const Grid = styled.div`
  margin: 25px 0;
  display: grid;
  grid: auto / 1fr;
  gap: 15px;
  width: 100vw;
  justify-items: center;
`;

export const GridItem = styled.div`
  display: flex;
  height: 150px;
  width: 150px;

  a,
  button {
    position: relative;
    text-decoration: none;
    display: flex;

    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    border: 3px solid #efefef;
    background-color: #fff;
    border-radius: 15px;
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.5);

    transition: box-shadow 0.35s, border-top-color 0.35s,
      border-right-color 0.7s, border-bottom-color 1.05s, border-left-color 1.4s;

    svg {
      color: #767676;
      height: 45px;
      width: 45px;
      transition: color 0.35s, transform 0.35s;
    }

    span {
      color: #464646;
      position: absolute;
      bottom: 5px;
      font-size: 18px;
      font-family: 'Roboto Slab';

      transition: color 0.35s;
    }

    &:hover {
      border-top-color: #4285f4;
      border-right-color: #db4437;
      border-bottom-color: #f4b400;
      border-left-color: #0f9d58;
      box-shadow: 3px 3px 10px 2px rgba(0, 0, 0, 0.5);

      svg {
        transform: scale(1.2);
      }
    }
  }

  &:nth-child(1) {
    a {
      &:hover {
        span {
          color: #4285f4;
        }

        svg {
          color: #f4b400;
        }
      }
    }
  }
  &:nth-child(2) {
    a {
      &:hover {
        span {
          color: #0f9d58;
        }

        svg {
          color: #db4437;
        }
      }
    }
  }
  &:nth-child(3) {
    a {
      &:hover {
        span {
          color: #db4437;
        }

        svg {
          color: #4285f4;
        }
      }
    }
  }
`;
