import React, {
  useState,
  useEffect,
  useCallback,
  createContext,
  useContext,
} from 'react';
import { AppProps } from 'interfaces';

import { useTranslation } from 'react-i18next';

interface LangContextData {
  lang: string;
  getLang(): void;
  setLang(language: string): void;
}

interface LangState {
  lang: string;
}

const LangContext = createContext<LangContextData>({} as LangContextData);

const LanguageProvider: React.FC<AppProps> = ({ children }) => {
  const { i18n } = useTranslation();

  const [data, setData] = useState<LangState>(() => {
    const lang = localStorage.getItem('@chosenLanguage') || navigator.language;
    // console.log(`this is on localStorage ${lang}`);
    i18n.changeLanguage(lang);

    return { lang };
  });

  const getLang = useCallback(() => {
    const lang = localStorage.getItem('@chosenLanguage');

    setData({ lang: lang || navigator.language });
  }, []);

  const setLang = useCallback((lang: string) => {
    localStorage.setItem('@chosenLanguage', lang);
    // i18n.changeLanguage(lang);
    setData({ lang });
  }, []);

  useEffect(() => {
    i18n.changeLanguage(data.lang);
  }, [data.lang]);

  return (
    <LangContext.Provider value={{ lang: data.lang, getLang, setLang }}>
      {children}
    </LangContext.Provider>
  );
};

function useLang(): LangContextData {
  const context = useContext(LangContext);

  if (!context) {
    throw new Error('useLang must be used within an LangProvider');
  }

  return context;
}

export { LanguageProvider, useLang };
