import styled from 'styled-components';
import { lighten } from 'polished';

export const NewContainer = styled.div`
  position: fixed;
  top: 75px;
  right: 15px;
  width: 45px;
  height: 45px;
  z-index: 2;

  button {
    background: #aed581;
    border: 3px solid #558b2f;
    color: #1b5e20;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 45px;
    height: 45px;

    border-radius: 50%;
    box-shadow: 0px 0px 10px 3px rgba(205, 232, 187, 0.75);
    transition: all 0.35s;
    svg {
      height: 30px;
      width: 30px;
      transition: all 0.35s;
    }

    &:hover {
      background-color: ${lighten(0.05, '#aed581')};
      border-color: ${lighten(0.1, '#558b2f')};
      box-shadow: 0 0 10px 5px rgba(205, 232, 187, 0.75);
      svg {
        color: ${lighten(0.05, '#1b5e20')};
      }
    }
  }
`;

export const Grid = styled.section`
  display: grid;
  grid: auto / 1fr 1fr 1fr 1fr;
  width: 100vw;
  max-width: 1000px;
  padding: 0px 15px;
  margin: 20px auto;

  transition: margin 0.35s;
  gap: 20px;

  @media screen and (max-width: 1100px) {
    max-width: unset;
    margin: 100px auto;
  }
  @media screen and (max-width: 1000px) {
    grid: auto / 1fr 1fr 1fr;
  }
  @media screen and (max-width: 660px) {
    gap: 20px;
    /* max-width: 420px; */
    grid: auto / 1fr 1fr;
  }

  @media screen and (max-width: 460px) {
    gap: 20px;
    max-width: 360px;
    grid: auto / 1fr;
    /* justify-items: center; */
  }
  /* place-items: center; */

  div {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    min-width: 200px;
    padding: 10px 5px;

    background: #fff;
    border-radius: 10px;
    border: 3px solid #efefef;
    box-shadow: 2px 5px 10px 3px rgba(0, 0, 0, 0.5);

    a {
      padding: 3px;
      position: relative;
      font-weight: bold;
      text-decoration: none;
      color: #332e2e;
      width: 100%;
      text-align: center;
      /* background: #c53030; */
      /* &:after,
      &:before {
        position: absolute;

        left: 50%;
        content: '';
        border: 0px solid black;
        width: 0%;
        transition: width 0.5s, border-width 0.5s, left 0.5s;
      }

      &:after {
        top: 0;
      }

      &:before {
        bottom: 0;
      }

      &:hover {
        &:after,
        &:before {
          left: 0%;
          border-width: 1px;
          width: 100%;
        }
      } */
    }

    button {
      /* background: #c53030; */
      position: absolute;
      right: -15px;
      bottom: -15px;

      display: flex;
      align-items: center;
      justify-content: center;

      margin-bottom: 3px;
      color: #c53030;
      border: 0;
      border-radius: 50%;
      background: #fff;

      box-shadow: 1px 2.5px 5px 1.5px rgba(0, 0, 0, 0.5);

      svg {
        height: 25px;
        width: 25px;
      }
    }
  }
`;
