import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
  useRef,
} from 'react';
import { AppProps } from 'interfaces';

import { useFirebase } from 'hooks/firebase';
import { useLang } from 'hooks/language';

import { getDatabase, set, ref, onValue, update } from 'firebase/database';

import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';

import Input from 'components/input';
import { v4 } from 'uuid';

import { toggleModal } from 'components/modal';

import { FaPlusCircle, FaRegSave, FaShare } from 'react-icons/fa';
import { FormWrapper } from './styles';

export interface FormData {
  ptbr: string;
  it: string;
  ref: string;
}
export interface CategoryProps {
  amount: number;
  id: string;
  it: string;
  ptbr: string;
  ref: string;
}

interface CallHandlers {
  onSuccess?: () => void;
  onError?: () => void;
  onFinish?: () => void;
}

interface CategoriesHookData {
  allcategories: CategoryProps[];
  categories: CategoryProps[];
  createCategory: (obj: object, __function?: CallHandlers) => void;
  updateCategory: (obj: object, __function?: CallHandlers) => void;
  deleteCategory: (id: string, __function?: CallHandlers) => void;
  CreateCategoryForm: React.FC;
}

const CategoryHook = createContext<CategoriesHookData>(
  {} as CategoriesHookData,
);

const CategoryProvider: React.FC<AppProps> = ({ children }) => {
  const { db_env } = useFirebase();
  const { lang } = useLang();
  const [language, setLanguage] = useState(
    lang.replace(/-/g, '').toLowerCase(),
  );

  const database = getDatabase();
  const [allcats, setAllCats] = useState<CategoryProps[]>([]);
  const [cats, setCats] = useState<CategoryProps[]>([]);

  const createCategory = useCallback(
    async (obj: CategoryProps, __handlers?: CallHandlers) => {
      await set(ref(database, `${db_env}/categories/${obj.id}`), {
        id: obj.id,
        ptbr: obj.ptbr,
        it: obj.it,
        ref: obj.ref,
        amount: 0,
      })
        .then(() => {
          if (__handlers !== undefined && __handlers.onSuccess !== undefined) {
            __handlers.onSuccess();
          }
        })
        .catch((e) => {
          console.error(e);
          if (__handlers !== undefined && __handlers.onError !== undefined) {
            __handlers.onError();
          }
        })
        .finally(() => {
          if (__handlers !== undefined && __handlers.onFinish !== undefined) {
            __handlers.onFinish();
          }
        });
    },
    [],
  );

  const updateCategory = useCallback(
    async (updates: object, __handlers: CallHandlers) => {
      await update(ref(database), updates)
        .then(() => {
          if (__handlers !== undefined && __handlers.onSuccess !== undefined) {
            __handlers.onSuccess();
          }
        })
        .catch((e) => {
          console.error(e);
          if (__handlers !== undefined && __handlers.onError !== undefined) {
            __handlers.onError();
          }
        })
        .finally(() => {
          if (__handlers !== undefined && __handlers.onFinish !== undefined) {
            __handlers.onFinish();
          }
        });
    },
    [],
  );

  const deleteCategory = useCallback(
    async (id: string, __handlers?: CallHandlers) => {
      await set(ref(database, `${db_env}/categories/${id}`), null)
        .then(() => {
          if (__handlers !== undefined && __handlers.onSuccess !== undefined) {
            __handlers.onSuccess();
          }
        })
        .catch((e) => {
          console.error(e);
          if (__handlers !== undefined && __handlers.onError !== undefined) {
            __handlers.onError();
          }
        })
        .finally(() => {
          if (__handlers !== undefined && __handlers.onFinish !== undefined) {
            __handlers.onFinish();
          }
        });
    },
    [],
  );

  useEffect(() => {
    setLanguage(lang.replace(/-/g, '').toLowerCase());
  }, [lang]);

  useEffect(() => {
    const retrieveCats = ref(database, `${db_env}/categories`);

    onValue(retrieveCats, (snapshot) => {
      const data = snapshot.val();
      let all = [];
      if (data !== null) {
        all = Object.keys(data)
          .map((item) => {
            return {
              ...data[item],
            };
          })
          .sort((a, b) => {
            return a[language].toLowerCase() > b[language].toLowerCase()
              ? 1
              : b[language].toLowerCase() > a[language].toLowerCase()
              ? -1
              : 0;
          });
      }

      setAllCats(all);
      setCats(all.filter((item) => item.ref >= 100));
    });
  }, [language]);

  return (
    <CategoryHook.Provider
      value={{
        allcategories: allcats,
        categories: cats,
        createCategory,
        updateCategory,
        deleteCategory,
        CreateCategoryForm,
      }}
    >
      {children}
    </CategoryHook.Provider>
  );
};

function useCategory(): CategoriesHookData {
  const context = useContext(CategoryHook);

  if (!context) {
    throw new Error('useCategory must be used within an CategoryHookProvider');
  }

  return context;
}

const CreateCategoryForm: React.FC = () => {
  const { createCategory, allcategories } = useCategory();
  const formRef = useRef<FormHandles>(null);
  const handleSubmit = useCallback(
    async (data: FormData) => {
      // const input = formCreateRef.current.getFieldRef('visible');
      const obj = {
        id: v4(),
        ptbr: data.ptbr,
        it: data.it,
        ref: (
          parseInt(
            allcategories
              .map((item) => item.ref)
              .sort((a, b) => (a > b ? -1 : b > a ? 1 : 0))
              .filter((item) => parseInt(item, 10) >= 100)[0] || '99',
            10,
          ) + 1
        ).toString(),
        amount: 0,
      };

      createCategory(obj, { onSuccess: () => formRef.current.reset() });
    },
    [allcategories],
  );

  const handleSaveAndNew = useCallback(() => {
    formRef.current.submitForm();
  }, []);

  const handleSaveAndFinish = useCallback(async () => {
    formRef.current.submitForm();
    toggleModal();
  }, []);

  return (
    <FormWrapper>
      <Form ref={formRef} onSubmit={handleSubmit}>
        <div>
          <Input name="ptbr" type="text" label="Nome da Categoria (PTBR):" />
        </div>
        <div>
          <Input name="it" type="text" label="Nome da Categoria (IT):" />
        </div>
        <div>
          <button
            style={{ margin: '20px 0 0 0' }}
            type="button"
            onClick={handleSaveAndNew}
          >
            <FaRegSave />
            <FaPlusCircle />
          </button>
          <button
            style={{ margin: '20px 0 0 0' }}
            type="button"
            onClick={handleSaveAndFinish}
          >
            <FaRegSave />
            <FaShare />
          </button>
        </div>
      </Form>
    </FormWrapper>
  );
};

export { CategoryProvider, useCategory };
