import React, {
  useState,
  useCallback,
  useEffect,
  useRef,
  useMemo,
} from 'react';
import {
  FaAngleDoubleLeft,
  FaAngleDoubleRight,
  FaSearchPlus,
} from 'react-icons/fa';

import { useWindow } from 'hooks/window';
import { useLang } from 'hooks/language';
import { useSpring } from 'react-spring';

import { Trans } from 'react-i18next';

import { useFirebase } from 'hooks/firebase';

import { useCategory } from 'hooks/category';
import { ref, onValue } from 'firebase/database';

import Container from 'components/container';
import Card from 'components/card';
import { toggleModal } from 'components/modal';

import 'styles/carousel.css';

import {
  Grid,
  GridItemContainer,
  GridItem,
  SearchIcon,
  AlteredCarousel,
  CarouselContainer,
  CarouselItem,
  SelectContainer,
  FrontFace,
  BackFace,
  CatButton,
  PaginationContainer,
  PaginationItem,
} from './styles';

interface CategoryProps {
  value: string;
  label: string;
}
interface ArtCategoryProps extends CategoryProps {
  ptbr: string;
  it: string;
}

interface LanguageProps {
  material: string;
  type: string;
  description: string;
  name: string;
}

interface ArtProps {
  category: CategoryProps[];
  id: string;
  based64: string;
  downloadURL: string;
  it: LanguageProps;
  ptbr: LanguageProps;
  ref: string;
  size: string;
  visible: boolean;
}

const GalleryPage: React.FC = () => {
  const { lang } = useLang();
  const [language, setLanguage] = useState(
    lang.replace(/-/g, '').toLowerCase(),
  );
  const { database, db_env } = useFirebase();

  const { width, height } = useWindow();
  const { allcategories } = useCategory();
  const selectRef = useRef<HTMLSelectElement>(null);
  // const [loading, setLoading] = useState(false);
  const [rawGallery, setRawGallery] = useState<ArtProps[]>([]);
  const [gallery, setGallery] = useState<ArtProps[]>([]);

  const [selectedItem, setSelectedItem] = useState(-1);
  const [selectedGallery, setSelectedGallery] = useState('');
  const [categories, setCategories] = useState<CategoryProps[]>([]);

  const [currentPage, setCurrentPage] = useState(1);

  const handleClick = useCallback(
    (theSource: string) => {
      const index = gallery.findIndex((item) => {
        return `${item.ref}` === theSource;
      });

      // setTimeout(() => {
      setSelectedItem(index);
      toggleModal();
      // }, 150);
    },
    [gallery],
  );

  const handleFilter = useCallback(
    (category: string) => {
      const content: ArtProps[] = [];
      rawGallery.map((item) => {
        return item.category.map((cat: CategoryProps) =>
          cat.value === category ? content.push(item) : null,
        );
      });

      setGallery(content);

      setSelectedItem(-1);
    },
    [rawGallery],
  );

  const handleSelectCategory = useCallback(() => {
    const selected = selectRef.current;
    const category = selected?.options[selected.selectedIndex].value;

    if (category !== undefined) {
      handleFilter(category);
      setCurrentPage(1);
      setSelectedGallery(category);
    }
  }, [rawGallery]);

  useEffect(() => {
    if (allcategories.length > 0) {
      const retrieveArts = ref(database, `${db_env}/arts`);
      onValue(retrieveArts, (snapshot) => {
        const data = snapshot.val();
        let filtered = [];
        if (data) {
          filtered = Object.keys(data)
            .map((item) => {
              const locally = localStorage.getItem(`@${data[item].ref}`);

              return {
                ...data[item],
                based64: locally,
                category: data[item].category.map((itemcat) => {
                  const index = allcategories
                    .map((cat) => {
                      return cat.ref;
                    })
                    .indexOf(itemcat);

                  return {
                    value: itemcat,
                    label: allcategories[index][language],
                    ptbr: allcategories[index].ptbr,
                    it: allcategories[index].it,
                  };
                }),
              };
            })
            .filter((item) => item.visible === true)
            .filter((item) => !!item.downloadURL);
        }

        const gatherCategories: ArtCategoryProps[] = [];
        filtered.map((item: ArtProps) => {
          return item.category.map((cat: ArtCategoryProps) => {
            return gatherCategories.push(cat);
          });
        });

        setCategories(
          gatherCategories
            .filter(
              (item: CategoryProps, index) =>
                gatherCategories.map((ff) => ff.value).indexOf(item.value) ===
                index,
            )
            .sort((a, b) =>
              a.label > b.label ? 1 : b.label > a.label ? -1 : 0,
            ),
        );

        // console.table(filtered);
        setRawGallery(filtered);
      });
    }
  }, [allcategories, lang]);

  useEffect(() => {
    setLanguage(lang.replace(/-/g, '').toLowerCase());
  }, [lang]);

  const handleSelectCategoryFromCard = useCallback(
    (id: string) => {
      toggleModal();
      handleFilter(id);
      setSelectedGallery(id);
      setCurrentPage(1);
      const selected = selectRef.current;
      if (selected) {
        selected.value = id;
      }
    },
    [handleFilter],
  );

  const range = (start, end) => {
    const length = end - start + 1;
    return Array.from({ length }, (_, idx) => idx + start);
  };

  const maxItems = 40;
  const DOTS = '•••';

  const offset = useMemo(() => {
    if (currentPage === 1) {
      return { init: 0, limit: maxItems };
    }

    return {
      init: (currentPage - 1) * maxItems,
      limit: currentPage * maxItems,
    };
  }, [gallery, currentPage]);

  const totalPageCount = useMemo(() => {
    return Math.ceil(gallery.length / maxItems) || 1;
    // return Math.ceil(1035 / maxItems) || 1;
  }, [gallery, currentPage]);

  const pagination = useMemo(() => {
    // const DOTS = '...';

    const siblingCount = 1;

    // const totalPageCount = Math.ceil(gallery.length / maxItems) || 1;
    const totalPageNumbers = siblingCount + 5;
    // return ['1', DOTS, '7', DOTS, '20'];

    if (totalPageNumbers >= totalPageCount) {
      return range(1, totalPageCount);
    }

    const leftSiblingIndex = Math.max(currentPage - siblingCount, 1);
    const rightSiblingIndex = Math.min(
      currentPage + siblingCount,
      totalPageCount,
    );

    const shouldShowLeftDots = leftSiblingIndex > 2;
    const shouldShowRightDots = rightSiblingIndex < totalPageCount - 2;

    const firstPageIndex = 1;
    const lastPageIndex = totalPageCount;

    if (!shouldShowLeftDots && shouldShowRightDots) {
      const leftItemCount = 3 + 2 * siblingCount;
      const leftRange = range(1, leftItemCount);

      return [...leftRange, DOTS, totalPageCount];
    }

    if (shouldShowLeftDots && !shouldShowRightDots) {
      const rightItemCount = 3 + 2 * siblingCount;
      const rightRange = range(
        totalPageCount - rightItemCount + 1,
        totalPageCount,
      );
      return [firstPageIndex, DOTS, ...rightRange];
    }

    if (shouldShowLeftDots && shouldShowRightDots) {
      const middleRange = range(leftSiblingIndex, rightSiblingIndex);
      return [firstPageIndex, DOTS, ...middleRange, DOTS, lastPageIndex];
    }
    return [];
  }, [currentPage, gallery]);

  const previousPage = useCallback(() => {
    setCurrentPage((state) => state - 1);
  }, []);

  const nextPage = useCallback(() => {
    setCurrentPage((state) => state + 1);
  }, []);
  // const [pagination, setPagination] = useState<string[]>([
  //   '1',
  //   DOTS,
  //   '7',
  //   DOTS,
  //   '15',
  // ]);

  const styledPagination = useSpring({
    opacity: totalPageCount > 1 ? 1 : 0,
  });

  return (
    <Container
      modalcontent={
        <AlteredCarousel
          autoPlay={false}
          infiniteLoop
          stopOnHover
          showStatus={false}
          showIndicators={false}
          showThumbs={false}
          useKeyboardArrows
          selectedItem={selectedItem}
        >
          {gallery.map((item) => (
            <CarouselContainer key={item.id}>
              <CarouselItem>
                <Card
                  front={{
                    content: (
                      <FrontFace custom__width={width} custom__height={height}>
                        <div>
                          <img
                            src={item.downloadURL}
                            alt={item[language].name}
                          />
                        </div>
                      </FrontFace>
                    ),
                  }}
                  back={{
                    content: (
                      <BackFace custom__width={width} custom__height={height}>
                        <div>
                          <p>{item[language].name}</p>
                          <section>
                            <span>
                              <p>
                                <Trans i18nKey="gallery.card.material" />
                                {/* Material: */}
                                <legend>
                                  {item[language].material
                                    ? item[language].material
                                    : '-'}
                                </legend>
                              </p>
                              <p>
                                <Trans i18nKey="gallery.card.dimension" />
                                {/* Dimensão: */}
                                <legend>{item.size ? item.size : '-'}</legend>
                              </p>
                            </span>
                            <span>
                              <p>
                                <Trans i18nKey="gallery.card.type" />
                                {/* Tipo: */}
                                <legend>
                                  {item[language].type
                                    ? item[language].type
                                    : '-'}
                                </legend>
                              </p>
                            </span>
                            <span>
                              <p>
                                <Trans i18nKey="gallery.card.description" />
                                {/* Descrição: */}
                                <legend>
                                  {item[language].description
                                    ? item[language].description
                                    : '-'}
                                </legend>
                              </p>
                            </span>
                          </section>
                          <section>
                            <span>
                              {item.category.map((cat, catindex) => (
                                <CatButton
                                  marked={cat.value === selectedGallery}
                                  data-id={`${selectedGallery}`}
                                  key={`button${
                                    cat.value
                                  }${catindex.toString()}`}
                                  onClick={() =>
                                    handleSelectCategoryFromCard(cat.value)
                                  }
                                  type="button"
                                >
                                  {cat[language]}
                                </CatButton>
                              ))}
                            </span>
                          </section>
                        </div>
                      </BackFace>
                    ),
                  }}
                />
              </CarouselItem>
            </CarouselContainer>
          ))}
        </AlteredCarousel>
      }
    >
      {/* <Modal /> */}

      <SelectContainer>
        <select
          ref={selectRef}
          name="categories"
          onChange={handleSelectCategory}
        >
          <option value="">
            <Trans i18nKey="gallery.category.selectone" />
          </option>

          {categories.map((item) => (
            <option key={item.value} value={item.value}>
              {item.label}
            </option>
          ))}
        </select>
      </SelectContainer>

      <PaginationContainer
        style={{
          ...styledPagination,
          pointerEvents: totalPageCount > 1 ? 'all' : 'none',
        }}
      >
        <button
          type="button"
          onClick={previousPage}
          disabled={currentPage === 1}
        >
          <FaAngleDoubleLeft />
        </button>

        {pagination.map((item) => (
          <PaginationItem
            marked={item.toString() === currentPage.toString()}
            onClick={() => setCurrentPage(parseInt(item, 10))}
            disabled={item === DOTS}
          >
            {item}
          </PaginationItem>
        ))}
        <button
          type="button"
          onClick={nextPage}
          disabled={currentPage === totalPageCount}
        >
          <FaAngleDoubleRight />
        </button>
      </PaginationContainer>

      <Grid amount={gallery.slice(offset.init, offset.limit).length}>
        {gallery.slice(offset.init, offset.limit).map((item) => (
          <div style={{ position: 'relative' }} key={item.id}>
            <SearchIcon>
              <FaSearchPlus />
            </SearchIcon>
            <GridItemContainer source={item.downloadURL}>
              <GridItem>
                <button
                  type="button"
                  onClick={() => handleClick(`${item.ref}`)}
                >
                  &nbsp;
                </button>
              </GridItem>
            </GridItemContainer>
          </div>
        ))}
      </Grid>

      {/* <PaginationContainer
        style={{
          ...styledPagination,
          pointerEvents: totalPageCount > 1 ? 'all' : 'none',
        }}
      >
        <button
          type="button"
          onClick={previousPage}
          disabled={currentPage === 1}
        >
          <FaAngleDoubleLeft />
        </button>

        {pagination.map((item) => (
          <PaginationItem
            marked={item.toString() === currentPage.toString()}
            onClick={() => setCurrentPage(parseInt(item, 10))}
            disabled={item === DOTS}
          >
            {item}
          </PaginationItem>
        ))}
        <button
          type="button"
          onClick={nextPage}
          disabled={currentPage === totalPageCount}
        >
          <FaAngleDoubleRight />
        </button>
      </PaginationContainer> */}
    </Container>
  );
};

export default GalleryPage;
