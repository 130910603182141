import styled, { css } from 'styled-components';
import { animated } from 'react-spring';

interface CardBackgroundProps {
  background?: string;
}

interface InfoButtonProps {
  open: boolean;
}

export const CardContainer = styled.div`
  position: relative;
  margin: auto 0;
  display: flex;
  height: 100%;
  width: 100%;
`;

export const Card = styled.div`
  display: flex;
  position: relative;
  width: 100vw;
  height: 100vh;
  max-height: 600px;
  max-width: 600px;
`;

export const CardFace = styled(animated.div)<CardBackgroundProps>`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;

  ${(props) =>
    props.background &&
    css`
      background-image: url(${props.background});
      background-position: 50% 50%;
      background-size: 100%;
      background-repeat: no-repeat;
    `}
`;

export const CardContent = styled.div``;
export const InfoButton = styled(animated.button)<InfoButtonProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: bolder;
  background: rgba(255, 255, 255, 0.75);

  width: 100%;
  height: 35px;
  border: 0;
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 4;

  transition: all 0.35s;

  svg {
    margin: 0 5px 0 0;
    transition: all 0.35s;
    height: 16px;
    width: 16px;
  }

  ${(props) =>
    props.open &&
    css`
      background: transparent;
      svg {
        transform: rotate(-135deg);
      }
    `}
`;
