import React, { useCallback, useRef, useEffect } from 'react';

import Container from 'components/container';
import Input from 'components/input';

import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';

import { useFirebase } from 'hooks/firebase';
import { useNavigate } from 'react-router-dom';

import { Wrapper, FormContent, Button } from './styles';

interface FormData {
  email: string;
  password: string;
}

const Auth: React.FC = () => {
  const navigate = useNavigate();
  const { signIn, userpresent } = useFirebase();
  const formRef = useRef<FormHandles>(null);
  // TODO: receive from hook
  // const [authenticated, setAuth] = useState<User>(() => {
  //   return {} as User;
  // });

  useEffect(() => {
    if (userpresent) {
      navigate('/admin', { replace: true });
    }
  }, [userpresent]);

  const handleSubmit = useCallback(async (data: FormData) => {
    try {
      signIn(data.email, data.password);
    } catch (e) {
      console.log(e);
    } finally {
      //
    }
  }, []);

  return (
    <Container usenav={false}>
      <Wrapper>
        <Form data-type="form" ref={formRef} onSubmit={handleSubmit}>
          <FormContent>
            <div>
              <Input name="email" placeholder="Email" />
            </div>
            <div>
              <Input name="password" type="password" placeholder="Senha" />
            </div>
          </FormContent>
          <Button type="submit">Submit</Button>
        </Form>
      </Wrapper>
    </Container>
  );
};

export default Auth;
