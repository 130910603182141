import React, { useState, ChangeEvent, useEffect, useCallback } from 'react';
import Container from 'components/container';
import { useStyle } from 'hooks/style';
import { useLoading } from 'hooks/loading';
import { useFirebase } from 'hooks/firebase';
import { uploadBytes, getDownloadURL, ref } from 'firebase/storage';

const Profile: React.FC = () => {
  const {
    gradient,
    changeUserBGGradientColors,
    applyDefaultBGGradientColors,
    commitUserBGGradientColors,
  } = useStyle();
  const { toggleLoading } = useLoading();
  const { db_env, storage } = useFirebase();

  const [colors, setColors] = useState<{ primary: string; secondary: string }>(
    gradient,
  );
  const [image, setImage] = useState(null);

  const handleInputChanged = (e: ChangeEvent<HTMLInputElement>) => {
    setColors((state) => ({ ...state, [e.target.id]: e.target.value }));
  };

  useEffect(() => {
    changeUserBGGradientColors(colors);
  }, [colors]);

  const saveChanges = async () => {
    commitUserBGGradientColors(colors);
  };

  const insertHomePagePhoto = useCallback(async () => {
    toggleLoading();
    if (image === null) {
      return;
    }
    const imageRef = ref(storage, `${db_env}/home/amanda`);

    await uploadBytes(imageRef, image).then((snapshot) => {
      toggleLoading();
      return getDownloadURL(snapshot.ref).then((url) => url);
    });
  }, [image]);
  return (
    <Container>
      <div
        style={{
          background: '#efefef',
          display: 'flex',
          flexDirection: 'column',
          margin: '50px auto',
          width: '600px',
          height: '300px',
        }}
      >
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <label
            htmlFor="primary"
            style={{
              display: 'flex',
              width: '100%',
            }}
          >
            <p>Primeira cor: </p>
            <input
              style={{
                margin: '0 0 0 10px',
              }}
              id="primary"
              type="color"
              defaultValue={gradient.primary}
              onChange={(e) => handleInputChanged(e)}
            />
          </label>
        </div>
        <div>
          <label
            htmlFor="secondary"
            style={{
              display: 'flex',
              width: '100%',
              margin: '20px 0 0 0 ',
            }}
          >
            <p>Segunda cor: </p>
            <input
              style={{
                margin: '0 0 0 10px',
              }}
              id="secondary"
              type="color"
              defaultValue={gradient.secondary}
              onChange={(e) => handleInputChanged(e)}
            />
          </label>
        </div>
        <div
          style={{
            margin: '20px 0 0 0',
            display: 'flex',
            justifyContent: 'space-around',
          }}
        >
          <div>
            <button type="button" onClick={saveChanges}>
              Salvar
            </button>
          </div>
          <div>
            <button type="button" onClick={applyDefaultBGGradientColors}>
              Redefinir ao padrão
            </button>
          </div>
        </div>
        <div
          style={{
            margin: '20px 0 0 0',
            display: 'flex',
            justifyContent: 'space-around',
          }}
        >
          <div>
            <label htmlFor="photo">
              <p>Foto da Página Principal:</p>
              <input
                id="photo"
                name="photo"
                type="file"
                accept="image/*"
                onChange={(event) => {
                  setImage(event.target.files[0]);
                }}
              />
            </label>
          </div>
          <div>
            <button
              type="button"
              onClick={insertHomePagePhoto}
              disabled={image === null}
            >
              Definir foto página inicial
            </button>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default Profile;
