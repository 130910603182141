import React from 'react';

import { Navigate } from 'react-router-dom';
import { useFirebase } from 'hooks/firebase';

interface PrivateRouteProps {
  outlet: JSX.Element;
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({ outlet }) => {
  const { userpresent } = useFirebase();
  const authenticationPath = '/admin/login';
  return userpresent ? (
    outlet
  ) : (
    <Navigate to={{ pathname: authenticationPath }} />
  );
};

export default PrivateRoute;
