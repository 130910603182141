import React, { useState, useRef, useCallback, ChangeEvent } from 'react';

import { useCategory } from 'hooks/category';
import { useFirebase } from 'hooks/firebase';
import { useLoading } from 'hooks/loading';

import { api } from 'services/api';

import { ref, getDownloadURL, uploadBytes } from 'firebase/storage';

import { set, ref as dbRef } from 'firebase/database';

import { v4 } from 'uuid';

import Container from 'components/container';
import Input from 'components/input';
import Textarea from 'components/textarea';

import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';

import { toggleModal } from 'components/modal';
import { useNavigate } from 'react-router-dom';

import { extractImageFileExtensionFromBase64 } from 'utils/toCropUtils';

import {
  FaCamera,
  FaRegSave,
  FaPlusSquare,
  FaEye,
  FaEyeSlash,
} from 'react-icons/fa';
import { sortIt } from 'utils/specialchars';
import {
  LanguageContainer,
  LanguageContent,
  CategoryContainer,
  CategoryLabel,
  ButtonCreateCategory,
  DimensionContainer,
  VisibleContainer,
  PhotoContainer,
  SaveButton,
  Wrapper,
} from './styles';

interface FormData {
  size: string;
  ptbrname: string;
  ptbrmaterial: string;
  ptbrtype: string;
  ptbrdescription: string;
  itname: string;
  itmaterial: string;
  ittype: string;
  itdescription: string;
  category: string[];
  visible: boolean;
}

const ArtsManagementInsert: React.FC = () => {
  const { toggleLoading } = useLoading();
  const navigate = useNavigate();
  const [language] = useState('ptbr');
  const { categories, CreateCategoryForm } = useCategory();
  const formRef = useRef<FormHandles>(null);
  const [image, setImage] = useState(null);
  const [compressedImage, setCompressedImage] = useState(null);
  const [artCategories, setArtCategories] = useState<string[]>(['1']);

  const [visible, setVisible] = useState(false);

  const { storage, database, db_env } = useFirebase();

  function bytesToSize(bytes: number) {
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];

    if (bytes === 0) {
      return '0 Byte';
    }

    const i = Math.floor(Math.log(bytes) / Math.log(1024));

    return `${(bytes / 1024 ** i).toFixed(2)} ${sizes[i]}`;
  }

  const handleVisibilityChange = useCallback(() => {
    setVisible((state) => !state);
  }, []);

  const handleFile = useCallback((blob: Blob) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      const fileExtension = extractImageFileExtensionFromBase64(reader.result);

      const file2b = new File([blob], 'amanda', {
        type: `image/${fileExtension}`,
      });
      setCompressedImage(file2b);
    };
    reader.readAsDataURL(blob);
  }, []);

  const preHandleFile = useCallback((blob: Blob) => {
    const file2b = new File([blob], 'prefile');

    const sreader = new FileReader();

    sreader.onloadend = () => {
      const original = document.querySelector('#resizedImage') as any;

      const imgOrig = new Image(600, 600);
      imgOrig.src = original.src;
      imgOrig.id = 'brandnew';

      imgOrig.onload = () => {
        const canvas = document.createElement('canvas');

        const context = canvas.getContext('2d');

        const canvasWidth = 600;
        const canvasHeight = 600;

        canvas.width = canvasWidth;
        canvas.height = canvasHeight;

        context.drawImage(imgOrig, 0, 0, canvasWidth, canvasHeight);

        canvas.toBlob(
          (nblob) => {
            if (nblob) {
              handleFile(blob);
            }
          },
          'image/jpeg',
          1,
        );
      };
    };

    sreader.readAsDataURL(file2b);
  }, []);

  const handlePhotoChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const file = event.target.files[0];

      const reader = new FileReader();

      reader.onload = (e) => {
        document
          .querySelector('#newImage')
          .setAttribute('src', e.target.result as string);

        document
          .querySelector('#originalImage')
          .setAttribute('src', e.target.result as string);
      };

      reader.onloadend = () => {
        const original = document.querySelector('#originalImage') as any;

        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');

        const canvasWidth = 1200;
        const canvasHeight = 1200;

        canvas.width = canvasWidth;
        canvas.height = canvasHeight;

        context.drawImage(original, 0, 0, canvasWidth, canvasHeight);

        canvas.toBlob(
          (blob) => {
            if (blob) {
              const nsrc = URL.createObjectURL(blob);
              document.querySelector('#resizedImage').setAttribute('src', nsrc);
              preHandleFile(blob);
            }
          },
          'image/jpeg',
          1,
        );
      };

      reader.readAsDataURL(file);

      setImage(file);
    },
    [],
  );

  const handleCheckbox = useCallback((category: string) => {
    setArtCategories((state) => {
      const filter = [...state];
      if (filter.includes('1')) {
        return [category];
      }

      if (filter.length === 1 && category === filter[0]) {
        return ['1'];
      }

      return state.indexOf(category) > -1
        ? state.filter((item) => item !== category)
        : [...state, category].sort((a, b) => (a > b ? 1 : b > a ? -1 : 0));
    });
  }, []);

  const handleSubmit = async (data: FormData) => {
    if (!!image && !!compressedImage) {
      console.log(
        `Original size: ${bytesToSize(
          image.size,
        )} / Compressed size: ${bytesToSize(compressedImage.size)}`,
      );
    }

    toggleLoading();
    const visibility = document.querySelector<HTMLInputElement>('#visible');

    const uuid = v4();
    const imageUUID = v4();
    const imageRef = ref(storage, `${db_env}/arts/${imageUUID}`);

    let httpref = '';

    if (image !== null) {
      httpref = await uploadBytes(imageRef, compressedImage).then(
        async (snapshot) => {
          return getDownloadURL(snapshot.ref).then((url) => url);
        },
      );

      const upload = new FormData();
      // upload.append('oldRef', reg.ref);
      upload.append('newRef', imageUUID);
      upload.append('newPhoto', image);
      upload.append('ext', image.type.replace('image/', ''));

      await api.post('/upload.php', upload, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
    }

    await set(dbRef(database, `${db_env}/arts/${uuid}`), {
      id: uuid,
      ref: image !== null ? imageUUID : null,
      downloadURL: httpref,
      size: data.size,
      ptbr: {
        name: data.ptbrname,
        material: data.ptbrmaterial,
        type: data.ptbrtype,
        description: data.ptbrdescription,
      },
      it: {
        name: data.itname,
        material: data.itmaterial,
        type: data.ittype,
        description: data.itdescription,
      },
      category: artCategories,
      visible: image === null ? false : visibility.checked,
    }).then(() => {
      toggleLoading();
      navigate(-1);
    });
  };

  return (
    <Container modalcontent={<CreateCategoryForm />}>
      <Form ref={formRef} onSubmit={handleSubmit}>
        <Wrapper>
          <LanguageContainer>
            <LanguageContent>
              <strong>Português</strong>
              <div>
                {/* <p>Nome:</p> */}
                <Input name="ptbrname" label="Nome" />
              </div>
              <div>
                <Textarea name="ptbrdescription" label="Descrição" />
              </div>
              <div>
                <div>
                  {/* <p>Material:</p> */}
                  <Input name="ptbrmaterial" label="Material" />
                </div>
                <div>
                  {/* <p>Tipo:</p> */}
                  <Input name="ptbrtype" label="Tipo" />
                </div>
              </div>
            </LanguageContent>
            <LanguageContent>
              <strong>Italiano</strong>
              <div>
                {/* <p>Nome:</p> */}
                <Input name="itname" label="Nome" />
              </div>
              <div>
                <Textarea name="itdescription" label="Descrição" />
              </div>
              <div>
                <div>
                  {/* <p>Material:</p> */}
                  <Input name="itmaterial" label="Material" />
                </div>
                <div>
                  {/* <p>Tipo:</p> */}
                  <Input name="ittype" label="Tipo" />
                </div>
              </div>
            </LanguageContent>
          </LanguageContainer>
          <CategoryContainer>
            <p>Categorias:</p>
            <div style={{ display: 'grid', grid: 'auto / 1fr 1fr 1fr 1fr' }}>
              {sortIt(categories, 'ptbr').map((item) => (
                <CategoryLabel
                  key={item.id}
                  htmlFor={`input${item.ref}`}
                  data-display={item[language]}
                  marked={artCategories.includes(item.ref)}
                >
                  <input
                    type="checkbox"
                    onClick={() => handleCheckbox(item.ref)}
                    disabled={item.ref === '1'}
                    readOnly
                  />
                </CategoryLabel>
              ))}
              <CategoryLabel
                htmlFor="input1"
                data-display="Sem categoria"
                marked={artCategories.includes('1')}
                disabled
              >
                <input type="checkbox" disabled readOnly />
              </CategoryLabel>
              <ButtonCreateCategory onClick={toggleModal} type="button">
                <FaPlusSquare />
              </ButtonCreateCategory>
            </div>
          </CategoryContainer>

          <DimensionContainer>
            <Input name="size" label="Dimensão" />
          </DimensionContainer>
          <VisibleContainer marked={visible}>
            <strong>Visível:</strong>
            <label htmlFor="visible">
              {visible ? <FaEye /> : <FaEyeSlash />}
              <input
                id="visible"
                name="visible"
                type="checkbox"
                defaultChecked={visible}
                onChange={handleVisibilityChange}
              />
            </label>
          </VisibleContainer>
          <PhotoContainer>
            <label htmlFor="photo">
              <FaCamera />
              <input
                id="photo"
                name="photo"
                type="file"
                accept="image/*"
                onChange={(event) => handlePhotoChange(event)}
              />
            </label>
            <div>
              {!!image && (
                <div>
                  <p>Nova Imagem:</p>
                  <img id="newImage" src="" alt="No Image" />
                </div>
              )}
            </div>
          </PhotoContainer>

          <SaveButton type="submit">
            <FaRegSave />
          </SaveButton>
        </Wrapper>
        <img
          id="originalImage"
          alt=""
          style={{ display: 'none' }}
          crossOrigin="anonymous"
        />
      </Form>
      <div style={{ display: 'none' }}>
        <img id="resizedImage" alt="none" crossOrigin="anonymous" />
      </div>
    </Container>
  );
};

export default ArtsManagementInsert;
