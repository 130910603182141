import React, { useEffect, useState, useCallback } from 'react';

import Container from 'components/container';
import { toggleModal } from 'components/modal';

import { useCategory } from 'hooks/category';

import { Link, useLocation } from 'react-router-dom';

import { FaTimesCircle, FaPlusCircle } from 'react-icons/fa';

import { NewContainer, Grid } from './styles';

export interface CategoryProps {
  id: string;
  ptbr: string;
  it: string;
  ref: string;
  amount: number;
}

export interface FormData {
  ptbr: string;
  it: string;
  ref: string;
}

const CategoriesManagement: React.FC = () => {
  const { categories, deleteCategory, CreateCategoryForm } = useCategory();
  const { pathname } = useLocation();

  const [cats, setCats] = useState<CategoryProps[]>([]);

  useEffect(() => {
    setCats(categories);
  }, [categories]);

  const handleDelete = useCallback(async (item: string) => {
    const confirmed = window.confirm(
      'Você está prestes a deletar PERMANENTEMENTE esta categoria.\n\nDeseja prosseguir com esta ação?',
    );
    if (confirmed) {
      deleteCategory(item);
    }
  }, []);

  return (
    <Container modalcontent={<CreateCategoryForm />}>
      <div>
        <NewContainer>
          <button type="button" onClick={toggleModal}>
            <FaPlusCircle />
          </button>
        </NewContainer>
        <Grid>
          {cats.map((item) => (
            <div key={item.id} style={{ display: 'flex' }}>
              <Link
                to={{ pathname: `${pathname}/update` }}
                state={{ ...item }}
                style={{ margin: '0 5px' }}
              >
                {item.ptbr} - {item.it}
              </Link>
              <button
                type="button"
                onClick={() => handleDelete(item.id)}
                style={{ margin: '0 5px' }}
              >
                <FaTimesCircle />
              </button>
            </div>
          ))}
        </Grid>
      </div>
    </Container>
  );
};

export default CategoriesManagement;
